<template>
  <div class="">
    <div class="remitance-detail" v-if="!isOrderDetailsTab">
      <div class="row justify-content-between mx-1 mt-4 mb-3">
        <span style="font-family: proxima-nova, sans-serif !important;font-size:13px;color:#bfbfbf">
          Settlement Period: {{remittenceDetails.settlementPeriod}}
        </span>
        <!-- <b-button
          variant="primary"
          class="ml-auto back-button mr-4 mb-3"
          @click="goBackToPreviousPage()"
          >Back</b-button> -->
        <img src="../../assets/SVG/Back.svg" class="back-btn" width="60px" @click="goBackToPreviousPage()">
      </div>
      <div class="row p-0 m-0 mb-2 mx-1 card-scroll">
        <div 
          class=" p-0 m-0 mb-2"
          v-for="(data, index) in cardData"
          :key="index"
        >
          <w-remittence-card
            class="m-1 p-3 remittence-card"
            :cardData="data"
            :modalTableDetails="modalTableDetails"
            :modalCurrency="modalCurrency"
          />
          
        </div>
      </div>
      <b-card class="m-2" :body-class="'card-padding'">
        <div class="row">
          <div class="col-md-3 mt-auto">
            <label class="tree-label">Order ID</label>
            <w-text-input
              :fieldType="'text'"
              :disabled="false"
              :defaultValue="orderId"
              :labelStyle="'register-label'"
              :validation="{ alpha_num: true }"
              :fieldName="'Order ID'"
              @textInputChange="getOrderId($event)"
            />
          </div>
          <div class="col-md-3 mt-auto">
            <label class="tree-label">Channel Order ID</label>
            <w-text-input
              :fieldType="'text'"
              :disabled="false"
              :defaultValue="channelOrderId"
              :labelStyle="'register-label'"
              :validation="{ alpha_num: true }"
              :fieldName="'Channel Order ID'"
              @textInputChange="getChannelOrderId($event)"
            />
          </div>
          <div class="col-md-3 mt-auto">
            <label class="tree-label">Purchase Date</label>
            <date-picker
              v-model="selectedPurchaseDate"
              :lang="'en'"
              range
              confirm
              @clear="clearPurchaseDate($event)"
              @confirm="getPurchaseDate($event)"
            ></date-picker>
          </div>
        </div>
      </b-card>
      <w-table
        class="table-data"
        :tableProperties="tableProperties"
        :tableLoading="tableLoading"
        :showRemittenceSearchFilter="true"
        @getSelectedPageEvent="getSelectedPage($event)"
        @getOrderDetailsPage="goToOrderDetailsPage($event)"
        @getSelectedPerPageEvent="getSelectedPerPage($event)"
      />
    </div>
    <div class="order-detail-page" v-else>
      <w-Order-Details-Table
        :orderIdParams="orderDetialsParams.orderId"
        :channelIdParams="remittenceDetails.channelId"
        :companyIdParams="remittenceDetails.companyId"
        @gotoBackPage="goBackToRemittanceIndividualPage()"
      />
    </div>
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import Modal from "../../widgets/ModalWidget";
import TextInput from "../../widgets/TextInput.vue";
import RemittenceCard from "../../widgets/RemittenceDetailsCard.vue";
import OrderDetailsTable from "../../widgets/OrderDetailsTables";
import RemittenceCollapseTable from "../../widgets/RemittenceCollapseTable.vue";
import { getRemittenceIndividualDetails } from "../../Service/RemittenceService";

export default {
  components: {
    Modal,
    DatePicker,
    "w-text-input": TextInput,
    "w-table": RemittenceCollapseTable,
    "w-Order-Details-Table": OrderDetailsTable,
    "w-remittence-card": RemittenceCard,
  },
  props: ["remittenceDetails"],
  created() {
    this.modalTableDetails = {
      "serviceFee":{
                    "Cost Of Advertising": this.remittenceDetails.costOfAdvertising,
                    "Tax Amount" : this.remittenceDetails.taxAmount
                  },
       "marketplaceFee":{
                    "FBA inbound Transportation Fee": this.remittenceDetails.fbainboundTransportationFee,
                    "FBA inbound Transportation Fee CGST" : this.remittenceDetails.fbainboundTransportationFeeCGST,
                    "FBA inbound Transportation Fee SGST" : this.remittenceDetails.fbainboundTransportationFeeSGST
                  }
    },
    this.modalCurrency = this.remittenceDetails.currency,
    this.settlementID = this.remittenceDetails.settlementId;
    this.remittanceID = this.remittenceDetails.reportId;
    this.cardData[0].cardText = this.remittenceDetails.channelName;
    this.cardData[1].cardText = this.formatDate(
      this.remittenceDetails.remittanceDate,
      "DD-MMM-YYYY"
    );
    if (this.remittenceDetails.previousReserveAmount != null) {
      this.cardData[2].cardText = `${this.remittenceDetails.currency} ${this.remittenceDetails.previousReserveAmount}`;
    } else {
      this.cardData[2].cardText = ``;
    }
    if (this.remittenceDetails.remittedAmount != null) {
      this.cardData[3].cardText = `${this.remittenceDetails.currency} ${this.remittenceDetails.remittedAmount}`;
    } else {
      this.cardData[3].cardText = ``;
    }
    if (this.remittenceDetails.currencyReserveAmount != null) {
      this.cardData[4].cardText = `${this.remittenceDetails.currency} ${this.remittenceDetails.currencyReserveAmount}`;
    } else {
      this.cardData[4].cardText = ``;
    }
    this.cardData[5].cardText = this.remittenceDetails.numberOfOrders;

    if (this.remittenceDetails.payableToAmazon != null) {
      this.cardData[6].cardText = `${this.remittenceDetails.currency} ${this.remittenceDetails.payableToAmazon}`;
    } else {
      this.cardData[6].cardText = ``;
    }
    if (this.remittenceDetails.serviceFee != null) {
      this.cardData[7].cardText = `${this.remittenceDetails.currency} ${this.remittenceDetails.serviceFee}`;
    } else {
      this.cardData[7].cardText = ``;
    }
    if (this.remittenceDetails.marketPlaceFee != null) {
      this.cardData[8].cardText = `${this.remittenceDetails.currency} ${this.remittenceDetails.marketPlaceFee}`;
    } else {
      this.cardData[8].cardText = ``;
    }
    if (this.remittenceDetails.miscellaneousAdjustment != null) {
      this.cardData[9].cardText = `${this.remittenceDetails.currency} ${this.remittenceDetails.miscellaneousAdjustment}`;
    } else {
      this.cardData[9].cardText = ``;
    }
    this.getRemittenceIndividualDetails();
  },
  data() {
    return {
      isOrderDetailsTab: false,
      orderDetialsParams: null,
      tableLoading: false,
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      orderId: "",
      startDate: "",
      endDate: "",
      channelOrderId: "",
      selectedPurchaseDate: "",
      settlementID: 0,
      remittanceID: 0,
      modalTableDetails:{},
      modalCurrency: "",
      cardData: [
        {
          cardTitle: "Channel",
          cardStyle: "Channel",
          cardText: null,
        },
        {
          cardTitle: "Date & Time",
          cardStyle: "Date&Time",
          cardText: null,
        },
        {
          cardTitle: "Previous Balance",
          cardStyle: "PreviousBalance",
          cardText: null,
        },
        {
          cardTitle: "Amount Paid",
          cardStyle: "AmountPaid",
          cardText: null,
        },
        {
          cardTitle: "Carryover Balance",
          cardStyle: "CarryoverBalance",
          cardText: null,
        },
        {
          cardTitle: "Number of Orders",
          cardStyle: "NumberofOrders",
          cardText: null,
        },
        //////
        {
          cardTitle: "Payable to Amazon",
          cardStyle: "payableToAmazon",
          cardText: null,
        },
        {
          cardTitle: "Service Fee",
          cardStyle: "serviceFee",
          cardText: null,
          sevicedetail: true,
        },
        {
          cardTitle: "Marketplace Fee",
          cardStyle: "marketplaceFee",
          cardText: null,
          isMarketplaceFee: true,
        },
        {
          cardTitle: "Miscellaneous Adjustment",
          cardStyle: "miscAdjustment",
          cardText: null,
        },
      ],
      tableProperties: {
        fields: [
          {
            key: "orderId",
            label: "Order ID",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "channelOrderId",
            label: "Channel Order ID",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "purchaseDate",
            label: "Purchase Date",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "product",
            label: "Product",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "status",
            label: "Status",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "orderAmount",
            label: "Order Amount",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "remittedAmount",
            label: "Remitted Amount",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "deductions",
            label: "Deductions",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: true,
          },
          {
            key: "remitence_Calculation",
            label: "",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
        ],
        items: [],
        perPage: 50,
        currentPage: 1,
        totalRows: 0,
      },
    };
  },
  methods: {
    formatDate(date, format) {
      return moment(date).format(format);
    },
    getSelectedPage(currentPage) {
      this.tableProperties.currentPage = currentPage;
      this.getRemittenceIndividualDetails();
    },
    getSelectedPerPage(perPage) {
      this.tableProperties.perPage = perPage;
      this.tableProperties.currentPage = 1;
      this.getRemittenceIndividualDetails();
    },
    goBackToPreviousPage() {
      this.$emit("closeBackButtonInTab");
    },
    getOrderId(event) {
      this.orderId = event;
      this.tableProperties.currentPage = 1;
      this.getRemittenceIndividualDetails();
    },
    getChannelOrderId(event) {
      this.channelOrderId = event;
      this.tableProperties.currentPage = 1;
      this.getRemittenceIndividualDetails();
    },
    clearPurchaseDate() {
      this.startDate = "";
      this.endDate = "";
      this.getRemittenceIndividualDetails();
    },
    getPurchaseDate() {
      this.startDate = moment(this.selectedPurchaseDate[0]).valueOf();
      this.endDate = moment(this.selectedPurchaseDate[1]).valueOf();
      this.getRemittenceIndividualDetails();
    },
    getRemittenceIndividualDetails() {
      this.tableLoading = true;
      this.tableProperties.items = [];
      getRemittenceIndividualDetails(
        this.tableProperties.currentPage - 1,
        this.tableProperties.perPage,
        this.settlementID,
        this.remittanceID,
        this.startDate,
        this.endDate,
        this.orderId,
        this.channelOrderId
      )
        .then((res) => {
          this.tableLoading = false;
          if (!res.errorCode) {
            let resp = res.response;
            this.tableProperties.items = resp.content;
            this.tableProperties.totalRows = resp.totalElements;
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    goBackToRemittanceIndividualPage() {
      this.isOrderDetailsTab = false;
    },
    goToOrderDetailsPage(orderDetails) {
      this.orderDetialsParams = orderDetails;
      this.isOrderDetailsTab = true;
    },
  },
};
</script>

<style scoped>
::v-deep .form-control{
  height: 37px !important;
}

.show-class {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  line-height: 2;
  text-align: left;
  color: #767676;
}
.remitance-detail ::v-deep .order-wise .remitteance-pagination{
  /* margin-left: auto;
  margin-top: 20px;
  margin-right: 5px; */
  margin: 20px 5px 0 auto;
}
.remitance-detail ::v-deep table thead tr th{
  text-align: center !important;
}
.remitance-detail ::v-deep table tbody tr td{
  text-align: center !important;
}
.remitance-detail ::v-deep table thead tr th:first-child{
  text-align: left !important;
}
.remitance-detail ::v-deep table tbody tr td:first-child{
  text-align: left !important;
}
.card-scroll{
  overflow-x: auto;
  flex-wrap: nowrap;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.card-scroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.card-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.remittence-card{
  border-radius: 5px;
  width: 200px;
}
.order-detail-page ::v-deep .whithout-tab-content{
    margin: 0;
  }
.table-data ::v-deep tbody tr{
  white-space: nowrap !important;
}


@media only screen and (max-width: 768px){
  .remitance-detail ::v-deep .mx-range-wrapper {
    width: 100%;
  }
  .remitance-detail ::v-deep .mx-calendar {
    width: 100%;
  }
  .remitance-detail ::v-deep .mx-calendar-content {
    width: 100%;
  }
  .remitance-detail ::v-deep .mx-datepicker-popup {
    left: 0px !important;
  }
}

@media only screen and (max-width: 340px){
  .remitance-detail ::v-deep .remitteance-pagination .page-link{
    min-width: 10px !important;
  }
  .remitance-detail ::v-deep .order-wise{
    padding-top: 16px;
    margin-left: 5px !important;
  }
  .remitance-detail ::v-deep .order-wise .custom-select{
    width: 70px !important;
    margin-right: 5px;
  }
  .back-btn{
    margin-top: 10px;
  }
}
</style>
