<template>
  <div class="">
    <b-table
      thead-tr-class="theadClass"
      :items="orderDetailsTableData.items"
      :fields="orderDetailsTableData.fields"
      responsive
    >
    <!-- for sku product details -->
      <template v-slot:cell(sku)="data">
        <span @click="goToProduct(data.item.sku)">
          {{ data.item.sku }}
        </span>
      </template>

      <template v-slot:cell(orderId)="data">
        <div class="row ml-2">
          <span class=" text-class"> Channel Order Id</span>
          <span class=" text-class"> {{ data.item.channelOrderId }}</span>
        </div>
      </template>
      <template v-slot:cell(Title)="data">
        <div class="item-title">{{ data.item.Title }}</div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: ["orderDetailsTableData"],
  methods: {
    goToProduct(event) {
      this.$emit("goToProduct", event);
    },
  }
};
</script>
<style scoped>
::v-deep .table th {
  font-size: 13px !important;
  font-weight: normal;
  color: #1B1C1D;
  font-weight: bold;
  opacity: 0.8;
}
::v-deep .theadClass {
  background-color: #F4F4F4 !important;
  text-align: center;
  font-family: "proxima-nova", sans-serif !important;
  text-align: left;
}
/* .text-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #1b1c1d;
} */
::v-deep .table tbody td {
  background-color: #fff !important;
  text-align: center;
  font-family: "proxima-nova", sans-serif !important;
  text-align: left;
  color: #1B1C1D;
  font-size: 13px;
}
.item-title {
  font-weight: normal;
  width: 14rem !important;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 50px;
  transition: max-height 0.3s ease;
}
.item-title:hover {
  white-space: normal;
  overflow: visible;
  height: auto;
  max-height: 500px;
  transition: max-height 0.3s ease;
  overflow-y: hidden;
}
</style>
<style>

.table{
  border: 0px !important;
}
.table .thead-light tr{
  background-color: #F4F4F4  !important;
}
.table .thead-light th{
  background-color: transparent;
  border:1px solid transparent;
}
.table tr:first-child td{
  border: 0px;
}
</style>
