<template>
  <div>
    <div class="row mx-0 pb-2 justify-content-between order-wise pl-1" >
      <div class="col-lg-4 col-md-6 col-6 d-flex align-items-center pl-1" v-if="isFilterButton" @click="openDesktop_filter">
        <img src="../assets/SVG/desktopFilter.svg" style="cursor:pointer"> 
        <span style="color:#2E8CD3; font-size:14px; cursor:pointer" >Filters</span>
      </div>
      <!-- search filter for sku wise remittance -->
      <div class="col-lg-3 col-md-3 col-12 ml-auto" v-if="showRemittenceStatusSearchFilter">
        <b-form-input
          v-model="skuTitelFilter"
          @keyup="getSkuTitelFilter($event)"
          type="search"
          id="filterInput"
          placeholder="Search by SKU/Title"
        ></b-form-input>
      </div>
      <div class="col-lg-2 col-md-2 col-12 text-right"  v-if="showRemittenceStatusSearchFilter && isPermission('allow_download_skuwise_remittance_report')">
        <button
          class="download-btn"
          @click="downloadSkuWise"
        >
          <span>Download</span>
        </button>
      </div>
      <div class="col-lg-12 col-md-12 col-12 p-0 d-flex align-items-end remitteance-pagination" v-if="tableProperties.totalRows >= 10">
        <!-- <div class="row justify-content-end"> -->
          <!-- <div class="text-right d-flex justify-content-end align-items-right py-0 pagination-class"> -->
            <span
              class="show-class mr-2"
              v-if="tableProperties.totalRows >= 10"
              >Show</span
            >
            <b-form-select
              v-if="tableProperties.totalRows >= 10"
              style="width: 80px; height: 30px; box-shadow: none;font-size:12px"
              v-model="tableProperties.perPage"
              :options="getPerPageOptions()"
              @change="getSelectedPerPage($event)"
            ></b-form-select>
            <span class="show-class ml-1" v-if="tableProperties.totalRows >= 10"
              >Entries</span
            >
            <b-pagination
              v-if="tableProperties.totalRows >= 10"
              :class="tableProperties.totalRows >= 10 ? 'pr-3' : 'pr-3 ml-auto'"
              :total-rows="tableProperties.totalRows"
              :per-page="tableProperties.perPage"
              :value="tableProperties.currentPage"
              aria-controls="my-table"
              :limit="1"
              @change="getSelectedPage($event)"
            ></b-pagination>
          <!-- </div> -->
          <!-- button for download in sku-wise remittance -->
        <!-- </div> -->
      </div>
      
    </div>

    <!-- for min max filter in sku wise remittance -->
    <!-- <b-card class="my-2 ml-2 mr-2" v-if="showRemittenceStatusSearchFilter">
      <div
        class="row p-0 m-0 d-flex justify-content-between align-items-center"
      >
        <div class="ml-3 sku-with-search">
          <b-form-input
            class="mr-2"
            style="width:250px; height:30px"
            v-model="skuTitelFilter"
            @keyup="getSkuTitelFilter($event)"
            type="search"
            id="filterInput"
            placeholder="Search by SKU/Title"
          ></b-form-input>
        </div>

        <div class="d-flex align-items-center py-3 pl-3 avg-order">
          <p class="mb-0 ml-1 fiter-title">Average Order Value</p>
          <p class="mb-0 mt-1 pl-2">
            <input
              type="number"
              name="averageOrderMin"
              min="0"
              max="100"
              placeholder="Min"
            />
            <input
              type="number"
              class="ml-2"
              name="averageOrderMax"
              min="0"
              max="100"
              placeholder="Max"
            />
          </p>
        </div>

        <div class="d-flex align-items-center py-3 pl-3 avg-order">
          <p class="mb-0 ml-1 fiter-title">Average Remitted Value</p>
          <p class="mb-0 mt-1 pl-2">
            <input
              type="number"
              name="averageRemittedMin"
              min="0"
              max="100"
              placeholder="Min"
            />
            <input
              type="number"
              class="ml-2"
              name="averageRemittedMax"
              min="0"
              max="100"
              placeholder="Max"
            />
          </p>
        </div>

        <div class="d-flex align-items-center py-3 pl-3 avg-order" :key="item">
          <p class="mb-0 ml-1 fiter-title">Unremitted Orders</p>
          <p class="mb-0 mt-1 pl-2">
            <input
              type="number"
              name="unremittedMin"
              min="0"
              max="100"
              placeholder="Min"
            />
            <input
              type="number"
              class="ml-2"
              name="unremittedMax"
              min="0"
              max="100"
              placeholder="Max"
            />
          </p>
        </div>

        <button class="filter-btn mr-2">
          <span>Filter</span>
        </button>
      </div>
    </b-card> -->

    <b-card class="ml-2 mt-3 mr-2 mb-3 mt-0 px-3">
      <b-table
        thead-class="theadClass"
        id="my-table"
        :items="tableProperties.items"
        :fields="tableProperties.fields"
        responsive="sm"
        show-empty
        outlined
        class="mt-3 tableStyle"
        :filter="filterSearch"
        :busy="tableLoading"
        empty-text="No Records Found"
        @sort-changed="sortingChanged"
        :filterIncludedFields="filterFields"
        sticky-header="200px"
        style="max-height:800px; overflow:auto;"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>

        <!-- remittance calculation for order wise remittance start -->
        <template v-slot:row-details="row">
          <div class="w-75 mx-auto">
            <div class="collapse-inline-table">
              <table class="table table-striped">
                <thead>
                  <tr class="table-bg">
                    <th class="table-bg" scope="col" style="color:#2e8cd3">
                      Transaction Type
                    </th>
                    <th class="table-bg" scope="col" style="color:#2e8cd3">
                      Transaction Date
                    </th>
                    <th class="table-bg" scope="col" style="color:#2e8cd3">
                      Product Title
                    </th>
                    <th class="table-bg" scope="col" style="color:#2e8cd3">
                      SKU
                    </th>
                    <th class="table-bg" scope="col" style="color:#2e8cd3">
                      Quantity
                    </th>
                    <th class="table-bg" scope="col" style="color:#2e8cd3">
                      Remitted Value
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-for="(settlement, index) in row.item.settelement"
                  :key="index"
                >
                  <tr v-if="settlement.amazonEasyShipCharges">
                    <td>Amazon Easy Ship Charges</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      @click="
                        openModal(
                          settlement.amazonEasyShipCharges,
                          1,
                          row.item.currency
                        )
                      "
                      style="cursor:pointer;"
                      class="link-column-item"
                    >
                      <span>
                        <span
                          v-if="
                            settlement.amazonEasyShipCharges
                              .easyshipChargeTotal != null
                          "
                          >{{ row.item.currency }}</span
                        >
                        {{
                          settlement.amazonEasyShipCharges.easyshipChargeTotal
                        }}
                      </span>
                    </td>
                  </tr>
                  <tr
                    v-for="(data, index) in settlement.orderCharges"
                    :key="index"
                  >
                    <td>Order Payment</td>
                    <td>{{ data.transactionDate }}</td>
                    <td>{{ data.productName }}</td>
                    <td>{{ data.sku }}</td>
                    <td>{{ data.quantity }}</td>
                    <td
                      @click="openModal(data, 0, row.item.currency)"
                      class="link-column-item"
                      style="cursor:pointer;"
                    >
                      <span>
                        <span v-if="data.remittedAmount != null">
                          {{ row.item.currency }}
                        </span>
                        {{ data.remittedAmount }}
                      </span>
                    </td>
                  </tr>
                  <!--  -->
                  <tr
                    v-for="(data, index) in settlement.otherCalculationDto"
                    :key="index"
                  >
                    <td>Others</td>
                    <td>{{ data.transactionDate }}</td>
                    <td>{{ data.productName }}</td>
                    <td>{{ data.sku }}</td>
                    <td>{{ data.quantity }}</td>
                    <td
                      @click="openModal(data, 0, row.item.currency)"
                      class="link-column-item"
                      style="cursor:pointer;"
                    >
                      <span>
                        <span v-if="data.remittedAmount != null">
                          {{ row.item.currency }}
                        </span>
                        {{ data.remittedAmount }}
                      </span>
                    </td>
                  </tr>
                  <!--  -->
                  <tr
                    v-for="(data, index) in settlement.refundCharges"
                    :key="index"
                  >
                    <td>Refund</td>
                    <td>{{ data.transactionDate }}</td>
                    <td>{{ data.productName }}</td>
                    <td>{{ data.sku }}</td>
                    <td>{{ data.quantity }}</td>
                    <td
                      @click="openModal(data, 0, row.item.currency)"
                      class="link-column-item"
                      style="cursor:pointer;"
                    >
                      <span>
                        <span v-if="data.remittedAmount != null">
                          {{ row.item.currency }}
                        </span>
                        {{ data.remittedAmount }}
                      </span>
                    </td>
                  </tr>
                  <tr v-if="settlement.cancellationCharges">
                    <td>Cancellation Charges</td>
                    <td>
                      {{
                        data && data.transactionDate ? data.transactionDate : ""
                      }}
                    </td>
                    <td>
                      {{ data && data.productName ? data.productName : "" }}
                    </td>
                    <td></td>
                    <td></td>
                    <td
                      @click="
                        openModal(
                          settlement.cancellationCharges,
                          2,
                          row.item.currency
                        )
                      "
                      style="cursor:pointer;"
                      class="link-column-item"
                    >
                      <span>
                        <span
                          v-if="
                            settlement.cancellationCharges
                              .cancellationChargesTotal != null
                          "
                          >{{ row.item.currency }}</span
                        >
                        {{
                          settlement.cancellationCharges
                            .cancellationChargesTotal
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
        <!-- end -->

        <template v-slot:cell(orderId)="data">
          <span
            class="link-column-item"
            @click="getOrderDetailsPage(data.item)"
            >{{ data.item.orderId }}</span
          >
        </template>

        <template v-slot:cell(product)="data">
          <span>
            <div v-for="list in data.item.product || data.item.sku" :key="list">
              {{ list }}
            </div>
          </span>
        </template>
        <template v-slot:cell(purchaseDate)="data">
          <span v-if="data.item.purchaseDate">
            {{ formatDate(data.item.purchaseDate, "DD-MMM-YYYY") }}
          </span>
        </template>
        <template v-slot:cell(orderAmount)="data">
          <span>
            <span v-if="data.item.orderAmount != null">
              {{ data.item.currency }}
            </span>
            {{ data.item.orderAmount }}
          </span>
        </template>
        <template v-slot:cell(remittedAmount)="data">
          <span>
            <span v-if="data.item.remittedAmount != null">
              {{ data.item.currency }}
            </span>
            {{ data.item.remittedAmount }}
          </span>
        </template>
        <template v-slot:cell(deductions)="data">
          <span>
            <span v-if="data.item.deductions != null">
              {{ data.item.currency }}
            </span>
            {{ data.item.deductions }}
          </span>
        </template>
        <!-- remittance calculation button for order wise remittance start -->
        <template v-slot:cell(remitence_Calculation)="row">
          <span
            v-if="row.item.settelement && isPermission('allow_view_remittance_calculations')"
            @click="row.toggleDetails"
            style="cursor:pointer;"
          >
            <span class="link-column-item">
              Show Remittance Calculation
              <span v-if="row.detailsShowing" class="ml-1">&#9650;</span>
              <span v-else class="ml-1">&#9660;</span>
            </span>
          </span>
        </template>
        <!-- end -->

        <!-- remitted order wise -->
        <template v-if="isRemittanceStatue" v-slot:cell(status)="data">
          <span
            class="pb-1"
            v-for="(status, index) in data.item.status"
            :key="index"
            style="display:flex; flex-direction:column;align-items:center"
          >
            <button
              class="ml-3"
              :class="getStatusClass(status)"
              style="cursor:default"
            >
              {{ status }}
            </button>
          </span>
        </template>
        <!-- end -->

        <template v-else v-slot:cell(status)="data">
          <button
            :class="getStatusClass(data.item.status)"
            style="cursor:default"
          >
            {{ data.item.status }}
          </button>
        </template>

        <template v-slot:cell(averageOrderValue)="data">
          <span v-if="data.item.averageOrderValue">
            {{ data.item.currency }} {{ data.item.averageOrderValue }}
          </span>
        </template>

        <template v-slot:cell(averageRemittedValue)="data">
          <span v-if="data.item.averageRemittedValue">
            {{ data.item.currency }} {{ data.item.averageRemittedValue }}
          </span>
        </template>

        <template v-slot:cell(sku_title)="data">
          <div class="text-left">
            <span class="sku-id">
              <label>{{ data.item.sku }}</label>
            </span>
            <br />
            <label class="sku-tilte">{{ data.item.skuTitle }}</label>
          </div>
        </template>
      </b-table>
    </b-card>
    <w-modal
      :trigger="showModal"
      :title="'Remittance Details'"
      :centered="true"
      :showModalTable="true"
      :modalTableDetails="modalTableDetails"
      :modalCurrency="modalCurrency"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :showEasyShip="showEasyShip"
      :showCancellation="showCancellation"
      @eventClose="closeModal()"
    />
  </div>
</template>

<script>
import moment from "moment";
import Modal from "./ModalWidget";
import { isAuthorized } from "../Service/CommonService";
export default {
  props: [
    "tableProperties",
    "showRemittenceSearchFilter",
    "showRemittenceStatusSearchFilter",
    "tableLoading",
    "isRemittanceStatue",
    "isFilterButton",
  ],
  components: {
    "w-modal": Modal,
  },
  data() {
    return {
      modalCurrency: null,
      showEasyShip: false,
      showCancellation: false,
      filterSearch: "",
      filterFields: [],
      modalTableDetails: {},
      showModal: false,
    };
  },
  methods: {
    // Permission based validation
    isPermission(key){
      return isAuthorized(key)
    },
    
    downloadSkuWise() {
      this.$emit("downloadSkuWiseEvent");
    },
    getStatusClass(status) {
      switch (status) {
        case "Shipped":
          return "shipped-status-class";
        case "Unshipped":
          return "unshipped-status-class";
        case "Canceled":
          return "canceled-status-class";
        case "Delivered":
          return "delivered-status-class";
        case "Pending":
          return "pending-status-class";
        case "PickedUp":
          return "pickedUp-status-class";
        default:
          return "";
      }
    },
    formatDate(date, format) {
      return moment(date).format(format);
    },
    getSkuTitelFilter(event) {
      this.filterFields = [];
      this.filterFields = ["sku", "skuTitle"];
      this.filterSearch = event.target.value;
    },
    openModal(event, value, currency) {
      this.modalCurrency = currency;
      if (value == 1) {
        this.showEasyShip = true;
        this.showCancellation = false;
      } else if (value == 2) {
        this.showCancellation = true;
        this.showEasyShip = false;
      } else {
        this.showEasyShip = false;
        this.showCancellation = false;
      }
      this.modalTableDetails = event;
      if (
        event.remittedAmount ||
        event.easyshipChargeTotal ||
        event.cancellationCharges
      ) {
        this.showModal = true;
      }
    },
    closeModal() {
      this.showModal = false;
    },
    getOrderDetailsPage(data) {
      this.$emit("getOrderDetailsPage", data);
    },
    sortingChanged(ctx) {
      this.$emit("sortField", ctx);
    },
    getPerPageOptions() {
      let options = [];
      options.push({ text: "5", value: 5 });
      for (let index = 5; index < 100; ) {
        index = index + 5;
        options.push({ text: index, value: index });
      }
      return options;
    },
    getSelectedPage(event) {
      this.$emit("getSelectedPageEvent", event);
    },
    getSelectedPerPage(event) {
      this.$emit("getSelectedPerPageEvent", event);
    },
    // for open filter in desktop layout
    openDesktop_filter(){
      this.$emit("openDesktop_filterEvent")
    }
  },
};
</script>
<style>
/* table.b-table > thead > tr > th[aria-sort="descending"]::after {
  opacity: 1;
  content: "\2193";
}
table.b-table > thead > tr > th[aria-sort="ascending"]::after {
  opacity: 1;
  content: "\2191";
} */
</style>
<style scoped>
::v-deep .card-body {
  padding: 0px;
}
::v-deep .td-checkbox-class {
  width: 50px;
}
::v-deep .theadClass {
  background-color: #efefef !important;
  text-align: center;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #1b1c1d;
}
::v-deep .table td {
  vertical-align: middle !important;
}
::v-deep .table thead th {
  vertical-align: middle !important;
  outline: none;
}
::v-deep td {
  padding-right: 16px;
  padding-left: 10px;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  text-align: left;
  color: #1b1c1d;
}
::v-deep .custom-checkbox .custom-control-label::before {
  border: 1.5px solid #4d515d !important;
  border-radius: 3px !important;
  margin-top: -5px;
}
::v-deep .custom-control-label::after {
  top: 0px;
}
::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2185d0 !important;
}
::v-deep .custom-control-label {
  margin-top: 9px;
  vertical-align: middle !important;
}
::v-deep .thClass {
  text-align: center !important;
}
::v-deep .tdClass {
  text-align: center !important;
}
::v-deep .bv-no-focus-ring {
  outline: none;
}
.download-btn {
  width: 100%;
  height: 30px;
  color: #FFFFFF;
  border-radius: 5px;
  border: solid 1px #2e8cd3;
  background-color: #2e8cd3;
}
.filter-btn {
  width: 80px;
  height: 30px;
  color: #fff;
  border-radius: 2px;
  border: solid 1px #2e8cd3;
  background-color: #2e8cd3;
}
/* .sku-with-search {
  display: flex;
  flex-direction: column;
  align-items: baseline;
} */
.sku-with-search ::v-deep .form-control {
  font-size: 12px;
}
.fiter-title {
  color: #1b1c1d;
  opacity: 0.7;
  font-size: 13px;
}
/* .sku-id {
  background: #ffd4cc;
  padding: 3px 15px;
} */
.sku-id label {
  color: #2E8CD3;
  font-size: 13px;
  font-weight: normal;
  font-weight: bold;
  white-space: pre;
}
.sku-tilte {
  color: #8C959E;
  font-size: 14px;
  font-weight: normal;
  width: 17rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 50px;
  transition: max-height 0.4s ease;
}
.sku-tilte:hover {
  white-space: normal;
  overflow: visible;
  height: auto;
  max-height: 500px;
  transition: max-height 0.4s ease;
  overflow-y: hidden;
}
.avg-order input {
  background: #fff;
  text-align: center;
  padding: 3px 0;
  border: 1px solid #ced4da;
  font-size: 13px;
  font-weight: normal;
  width: 50px;
}

.drop {
  width: 163px;
  height: 30px;
  background-color: #2e8cd3;
  margin-left: 20px;
}
.show-class {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  line-height: 2;
  text-align: left;
  color: #767676;
  margin-left: auto;
}
.link-label {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #2e8cd3;
}
.order-button {
  background-color: white;
  border: 1px solid #2e8cd3;
  padding: 6px;
  font-family: SourceSansPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #2e8cd3;
}
.link-column-item {
  cursor: pointer;
  color: #2e8cd3;
  white-space: nowrap;
}
.collapse-inline-table thead th {
  color: #fff;
  position: sticky;
  top: -1px;
  background: #0071c1;
}
.collapse-inline-table {
  max-height: 300px;
  overflow: auto;
}

/* for pagination style */
.pagination-class ::v-deep .pagination {
  margin-bottom: 0;
}
.pagination-class ::v-deep .pagination .page-item .page-link {
  font-size: 25px;
  height: fit-content;
}
.pagination-class ::v-deep .pagination .active {
  text-align: center;
}
.pagination-class ::v-deep .pagination .active .page-link {
  font-size: 14px !important;
  padding: 11px 7px;
}
.remitteance-pagination{
  display: flex;
  align-items: center;
  text-align: right;
}
</style>
<style>
.tableStyle thead th {
  position: sticky !important;
  top: -1px !important;
  background-color: #efefef !important;
}

.table{
  border: 0px !important;
}
.table .thead-light tr{
  background-color: #F4F4F4  !important;
}
.table .thead-light th{
  background-color: transparent;
  border:1px solid transparent;
}
.table tr:first-child td{
  border: 0px;
}
@media screen and (max-width:330px) {
  .remitteance-pagination{
    display: contents !important;
    justify-content: space-evenly;
  }
  .show-class{
    margin-left: 0 !important;
  }
}
</style>
