<template>
  <div>
    <div class="row">
      <div class="col-md-6 auto_inventory">
        <b-card class="m-3">
          <template class="one" v-slot:header>
            <b-card-text>Auto-Inventory Sync Settings</b-card-text>
          </template>
          <b-row>
            <div class="col-md-5 d-flex justify-content-start align-items-center">
              <p class="paragraph_text" @click="inventorySettings($event)">
                Inventory sync to channel <span class="warning_icon">!</span>
              </p>
            </div>
            <div class="col-md-7 pt-0 px-0" style="border-left:1px solid #d9d9d9">
              <div class="d-flex justify-content-around" style="background-color: #f3f3f4; padding:10px 0">
                <p class="mb-0 paragraph_text">Channel</p>
                <p class="mb-0 paragraph_text">On/Off</p>
              </div>
              <div class="d-flex justify-content-around" style="padding:13px 0">
                <p class=" paragraph_text">Amazon India</p>
                <b-form-checkbox
                    class="pl-5"
                    v-model="marketplaceFulfilledEnabled"
                    name="check-button"
                    switch
                    buttons
                    button-variant="success"
                    style="display: inline-block"
                  ></b-form-checkbox>
              </div>
              <div class="d-flex justify-content-around" style="padding:13px 0">
                <p class=" paragraph_text">Flipkart-St-1</p>
                <b-form-checkbox
                    class="pl-5"
                    v-model="marketplaceFulfilledEnabled"
                    name="check-button"
                    switch
                    buttons
                    button-variant="success"
                    style="display: inline-block"
                  ></b-form-checkbox>
              </div>
            </div>
          </b-row>
        </b-card>
      </div>
      <div class="col-md-6 low_stock">
        <b-card class="m-3">
          <template class="one" v-slot:header>
            <b-card-text>Low Stock Settings</b-card-text>
          </template>
          <b-row>
            <div class="col-md-6">
              <label class="pr-5 paragraph_text">Low Stock Limit <span class="warning_icon">!</span></label>
            </div>
            <div class="col-md-6">
              <w-text-input
                :fieldType="'text'"
                style="display:inline-block"
                v-model="fulfillmentLatency"
                :className="'w-25'"
                :disabled="false"
                :defaultValue="''"
                :labelStyle="'register-label'"
                @textInputChange="getFulfilmentLatency($event)"
              />
            </div>
          </b-row>
          <b-row class="divider" style="border-top:1px solid #e0e0e0">
            <b-col>
              <label class="pr-5 paragraph_text">
                Preferred Channel On Low Stock <span class="warning_icon">!</span>
              </label>
            </b-col>
            <b-col>
              <b-form-radio class="label_text" v-model="selectedd" value="A">Amazon_St-1</b-form-radio>
              <b-form-radio class="label_text" v-model="selected" value="B">Flipkart-St_1</b-form-radio>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

    <b-card class="mx-3 stock_alert">
      <template class="one" v-slot:header>
        <b-card-text>Stocks Alert</b-card-text>
      </template>
      <b-row>
        <div class="col-md-4 d-flex justify-content-start align-items-center">
          <p class="set-stock-button paragraph_text" @click="inventorySettings($event)">
            Low & out of stock alerts <span class="warning_icon">!</span>
          </p>
        </div>
        <div class="col-md-8 pt-0">
          <b-row class="d-flex" style="background-color: #f4f4f4;">
            <div class="col-md-6 paragraph_text">Seller Warehouse</div>
            <div class="col-md-6 paragraph_text">Marketplace Warehouse</div>
          </b-row>
          <b-row>
            <b-col style="border-left:1px solid #d9d9d9">
              <template>
                <div>
                  <b-form-checkbox class="label_text"
                    style="display:inline-block"
                    id="checkbox-1"
                    v-model="status1"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                  >Show low Stock Alert</b-form-checkbox>
                </div>
              </template>
              <template>
                <div class="mt-3">
                  <b-form-checkbox class="label_text"
                    style="display:inline-block"
                    id="checkbox-2"
                    v-model="status2"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="nott-accepted"
                  >Show out of stock alert</b-form-checkbox>
                </div>
              </template>
            </b-col>
            <b-col class="ml-2" style="border-left:1px solid #d9d9d9">
              <template>
                <div>
                  <b-form-checkbox class="label_text"
                    style="display:inline-block"
                    id="checkbox-3"
                    v-model="status3"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                  >Show low Stock Alert </b-form-checkbox>
                </div>
              </template>
              <template>
                <div class="mt-3">
                  <b-form-checkbox class="label_text"
                    style="display:inline-block"
                    id="checkbox-4"
                    v-model="status4"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                  >Show out of stock alert </b-form-checkbox>
                </div>
              </template>
            </b-col>
          </b-row>
        </div>
      </b-row>
    </b-card>
    <div class="container mt-4 pr-0 d-flex justify-content-end">
      <b-button
        type="submit"
        class="save mr-4"
        size="sm"
        @click="gobackProducts()"
      >Save</b-button>
      <b-button class="cancel ">Cancel</b-button>
    </div>
  </div>
</template>

<script>
import TextInput from "../../widgets/TextInput.vue";
export default {
  components: {
    "w-text-input": TextInput
  },
  data() {
    return {
      selected: "A",
      status1: 'not-accepted',
      status2: 'nott-accepted',
    };
  },
  methods: {
    gobackProducts() {
      this.$emit("backfromSettings", false);
    }
  }
};
</script>

<style>
  .auto_inventory .custom-switch .custom-control-label::before{
    width: 2rem;
    left: -2.15rem;
    background: #f51313;
    border-color: #f51313 !important;
  }
  .auto_inventory input[type="checkbox"] + label:before{
    height: 17px
  }
  .auto_inventory .custom-switch .custom-control-label::after{
    left: calc(-2.25rem + 5px)
  }
  .auto_inventory .custom-control-input:checked~.custom-control-label::before{
    background: #4bec1c !important;
    border-color: #4bec1c !important
  }
  .auto_inventory .custom-switch .custom-control-label::after{
    background: #ffffff !important;
    border-color: #fff !important
  }
  .stock_alert input[type="checkbox"] + label:before{
    width: 15px;
    height: 15px
  }
  
</style>
<style scoped>
.auto_inventory{
  padding-right: 0;
  padding-top: 0;
}
.low_stock{
  padding-left: 0;
  padding-top: 0;
}
.auto_inventory .card-body{
  padding: 0 15px 12px 1.25rem;
}
.auto_inventory .card-header,
.low_stock .card-header,
.stock_alert .card-header{
  background: #fff;
  border-bottom: solid 1px #d9d9d9;
}
.auto_inventory .card-header .card-text,
.low_stock .card-header .card-text,
.stock_alert .card-header .card-text{
  color: #1b1c1d;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
} 
.stock_alert .card-body{
  padding: 0 15px 12px 1.25rem;
}
.warning_icon{
  border: 1px solid #f2943a;
  border-radius:50%; 
  font-size:7px; 
  padding:1px 4px; 
  color:#f2943a;
  vertical-align:middle;
} 
.paragraph_text{
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: #1b1c1d;
}
.label_text{
  color: #1b1c1d;
  font-family: Roboto;
  font-size: 14px;
}
.save {
  background: #5fa6e7;
  border-radius: 5px;
  border: solid 1px #5fa6e7;
  padding: 3px 60px;
}
.cancel {
  border-radius: 5px;
  border: solid 1px #5fa6e7;
  background-color: #ffffff;
  color: #5fa6e7;
  padding: 3px 50px;
}
.divide {
  margin-top: -20px;
  margin-bottom: -20px;
}
.divider {
  margin-right: 0px;
  margin-top: 15px;
}
</style>