<template>
   <div class="activity_log">
       <div class="select_duration">
            <b-card class="m-3">
                <div class="col-md-2 p-0">
                    <p class="m-1 p-0 ml-4 activity_label">From Date</p>
                    <date-picker 
                        class="ml-4 mt-1"
                        v-model="time1" 
                        :lang="'en'" 
                        valuetype="format"
                        @confirm="selectDate($event)"
                    >
                    </date-picker>
                </div>
                <div class="col-md-2 ml-5 p-0">
                    <p class="m-1 p-0 activity_label">To Date</p>
                    <date-picker 
                        class="ml-1 mt-1"
                        v-model="time1" 
                        :lang="'en'" 
                        valuetype="format"
                        @confirm="selectDate($event)"
                    >
                    </date-picker>
                </div>
            </b-card>
        </div>
       <div class="activity_table">
        <b-card class="m-3">
            <label class="mb-0 activity_heading">Activity Logs</label>
            <w-table :tableData="tabledata" @imageeEvent="event($event)"></w-table>
        </b-card>
       </div>
        <div class="row d-flex justify-content-end">
        <b-button type="submit" class="back_btn" @click="goProducts()">Back</b-button>
      </div>
   </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import ReportsTable from "../../widgets/ReportsTable.vue";
export default {
    components:{
    DatePicker,
    "w-table": ReportsTable,
    },
    data(){
        return{
         tabledata:{
             fields:[
                 {
                     key:"date",
                     label:"Date"
                 },
                 {
                     key:"time",
                     label:"Time"
                 },
                 {
                     key:"activity",
                     label:"Activity"
                 },
                 {
                     key:"workhouse",
                     label:"Workhouse"
                 },
                 {
                     key:"channel",
                     label:"Channel"
                 }
             ],
            items: [
                { date: '31-03-2020', time: '13:50:26', activity: 'MRP was changed from ₹650 to ₹699', workhouse: 'NA', channel: 'Flipkart_st-1' },
                { date: '31-03-2020', time: '13:11:00', activity: 'Created new alias LS-04', workhouse: 'NA', channel: 'NA' } 
              ]
         }
        }
    },
    methods:{
        goProducts(){
            this.$emit("backfromLogs", false);
        }
    }
}
</script>
<style>
  .activity_table .table tbody td{
    color: #1b1c1d;
    font-family: Roboto;
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 15px;  
  }
</style>
<style scoped>
.activity_log .select_duration .card-body{
    display: flex;
    padding-left: 0;
}
.activity_label{
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: 600;
  color: #767676;
}
.activity_log .activity_table .card-body{
    padding-top: 10px;
}
.activity_heading{
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: #1b1c1d;
}
.back_btn {
   border-radius: 3px;
  border: solid 1px #5fa6e7;
  padding: 3px 60px;
  background: #5fa6e7;
  margin-right: 31px
}
</style>