<template>
  <div>
    <div>
      <b-tabs
        content-class="mt-0"
        ref="tabs"
        class="report-tabs"
        v-model="tabIndex"
        align="center"
      >
        <b-tab
          title="DASHBOARD"
          @click="getActiveTab('dashboard')"
          v-if="permission[0].isPermission"
          active
        >
          <w-dashboard
            :channelListOptions="channelList"
            ref="dashboardTab"
            :isGetData="getDataTab === 'dashboard'"
            class="remittance-dashboard"
          />
        </b-tab>
        <b-tab
          title="REMITTANCES"
          @click="getActiveTab('remittance')"
          v-if="permission[1].isPermission"
        >
          <!-- <div v-if="tabIndex == 1" > -->
          <w-remittence-details
            @closeBackButtonInTab="closeBackButtonInTab()"
            v-if="showBackButton"
            :remittenceDetails="remittenceDetails"
            :isGetData="getDataTab === 'remittanceDetail'"
            ref="remittanceDetailTab"
          />
          <w-remittence
            v-else
            @showBackButtonInTab="showBackButtonInTab($event)"
            :channelListOptions="channelList"
            :isGetData="getDataTab === 'remittanceDetail'"
            ref="remittanceDetailTab"
          />
          <!-- </div> -->
        </b-tab>
        <b-tab
          title="SKU-WISE REMITTANCES"
          @click="getActiveTab('skuwise')"
          v-if="permission[2].isPermission"
          class="sku-wise"
        >
          <w-remittence-sku
            :channelListOptions="channelList"
            ref="skuwiseTab"
            :isGetData="getDataTab === 'skuwise'"
          />
        </b-tab>
        <b-tab
          title="ORDER-WISE REMITTANCES"
          @click="getActiveTab('orderwise')"
          v-if="permission[3].isPermission"
          class="status"
        >
          <w-remittence-status
            :channelListOptions="channelList"
            ref="orderwiseTab"
            :isGetData="getDataTab === 'orderwise'"
          />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Remittence from "../components/Remittences/RemittenceTab.vue";
import RemittancesSKU from "../components/Remittences/SkuRemittence.vue";
import RemittenceStatus from "../components/Remittences/RemittedStatus.vue";
import RemittancesDashboard from "../components/Remittences/RemittencesDashboard.vue";
import RemittenceDetails from "../components/Remittences/RemittenceIndividualDetails.vue";
import { getChannelList, isAuthorized } from "../Service/CommonService";
export default {
  components: {
    "w-remittence": Remittence,
    "w-dashboard": RemittancesDashboard,
    "w-remittence-status": RemittenceStatus,
    "w-remittence-sku": RemittancesSKU,
    "w-remittence-details": RemittenceDetails
  },
  data() {
    return {
      remittenceDetails: {},
      channelList: [],
      showBackButton: false,
      tabIndex: 0,
      getDataTab:"",
      permission:[
        {
          title:"dashboard",
          isPermission:isAuthorized('allow_view_remittance_dashboard')
        },
        {
          title:"remittance",
          isPermission:isAuthorized('allow_view_remittance_reports')
        },
        {
          title:"sku-wise",
          isPermission:isAuthorized('allow_view_skuwise_remittance_report')
        },
        {
          title:"order-wise",
          isPermission:isAuthorized('allow_view_orderwise_remittance_calculations')
        }
      ]
    };
  },

   created(){
    this.getChannelList(); // call for channel api

    if(isAuthorized('allow_view_remittance_dashboard')){
      this.getDataTab = "dashboard"
    }else if(isAuthorized('allow_view_remittance_reports')){
      this.getDataTab = "remittanceDetail"
    }else if(isAuthorized('allow_view_skuwise_remittance_report')){
      this.getDataTab = "skuwise"
    }else if(isAuthorized('allow_view_orderwise_remittance_calculations')){
      this.getDataTab = "orderwise"
    }
  },

  methods: {
    getActiveTab(tabName){
      this.activeTab = tabName;
      if(this.activeTab === "dashboard"){
        this.$refs.dashboardTab.getData();
      }
      else if(this.activeTab === "remittance"){
        this.$refs.remittanceDetailTab.getData();
      }
      else if(this.activeTab === "skuwise"){
        this.$refs.skuwiseTab.getData();
      }
      else if(this.activeTab === "orderwise"){
        this.$refs.orderwiseTab.getData();
      }
    },
    showBackButtonInTab(event) {
      this.showBackButton = true;
      this.remittenceDetails = event;
    },
    closeBackButtonInTab() {
      this.showBackButton = false;
    },
    getChannelList() {
      getChannelList()
        .then((res) => {
          if (res) {
            res.forEach((element) => {
              this.channelList.push({
                id: element.channelId,
                label: element.channelName,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

  }
}
</script>

<style scoped>
@media screen and (min-width:800.98px) and (max-width:940px){
  .report-tabs ::v-deep .nav-tabs .nav-link{
    padding: 0;
  }
}
@media screen and (min-width:480.98px) and (max-width:630px){
  .report-tabs ::v-deep .nav-tabs{
    padding-left: 140px;
  }
}
@media screen and (max-width: 750px) {
  .remittance-dashboard {
    margin-top: 2rem;
  }
}
</style>
