<template>
  <div>
    <b-card class="m-3">
      <div class="row">
        <div class="col-md-3 p-0">
          <label class="ml-3 p-0 ledger_label">Link Warehouse</label>
          <w-dropdown
            style="width:300px"
            class="ml-3"
            :label-text="''"
            :selectedValue="''"
            :selectOption="generateList"
            @selectedOption="getSelectedType($event)"
          />
        </div>
        <div class="col-md-3 p-0">
          <label class="ml-4 p-0 ledger_label">Status</label>
          <w-dropdown
            style="width:300px"
            class="ml-4"
            :label-text="''"
            :selectedValue="''"
            :selectOption="generateList"
            @selectedOption="getSelectedType($event)"
          />
        </div>

        <div class="col-md-2 p-0">
          <p class="m-1 p-0 ml-4 ledger_label">From Date</p>
          <date-picker 
            class="ml-4 mt-1"
            v-model="time1" 
            :lang="'en'" 
            valuetype="format"
            @confirm="selectDate($event)"
          >
          </date-picker>
        </div>

         <div class="col-md-2 ml-5 p-0">
          <p class="m-1 p-0 ledger_label">To Date</p>
          <date-picker 
            class="ml-1 mt-1"
            v-model="time1" 
            :lang="'en'" 
            valuetype="format"
            @confirm="selectDate($event)"
          >
          </date-picker>
        </div>

      </div>
    </b-card>
    <b-card class="m-3 ledger_table">
      <w-table :tableData="tabledata" @imageeEvent="event($event)"></w-table>
    </b-card>
    <div class="row d-flex justify-content-end">
      <b-button
        type="submit"
        class="save"
        @click="goTooProducts()"
      >Back</b-button>
    </div>
  </div>
</template>

<script>
import Dropdown from "../../widgets/Dropdown.vue";
import DatePicker from "vue2-datepicker";
import ReportsTable from "../../widgets/ReportsTable.vue";
export default {
  components: {
    DatePicker,
    "w-dropdown": Dropdown,
    "w-table": ReportsTable
  },

  data() {
    return {
      time1:null,
      tabledata: {
        fields: [
          {
            key: "date",
            label: "Date"
          },
          {
            key: "time",
            label: "Time"
          },
          {
            key: "activity",
            label: "Activity"
          },
          {
            key: "stockCount",
            label: "Stock Count"
          },
          {
            key: "closingStock",
            label: "Closing Stock"
          }
        ],
        items: [
                { date: '31-03-2020', time: '16:48:08', activity: 'For order', stockCount: -1, closingStock: 1 },
                { date: '31-03-2020', time: '20:04:00', activity: 'Adjustment', stockCount: 2, closingStock: 0 }, 
                { date: '31-03-2020', time: '10:00:16', activity: 'base stock', stockCount: 2, closingStock: 0 } 
              ]
      }
    };
  },
  methods: {
    goTooProducts() {
      this.$emit("backfromLedger", false);
    }
  }
};
</script>

<style>
  .ledger_table .table tbody td{
    color: #1b1c1d;
    font-family: Roboto;
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 15px;  
  }
</style>
<style scoped>
.ledger_label{
  font-family: ProximaNova;
  font-size: 14px;
  font-weight: 600;
  color: #767676;
}
.save {
  border-radius: 3px;
  border: solid 1px #5fa6e7;
  padding: 3px 60px;
  background: #5fa6e7;
  margin-right: 31px
}
</style>