<template>
  <div class="remitence-details-card" :style="getStyleBorder()">

    <div v-if="cardData.cardTitle === 'Marketplace Fee'" class="remitence-details-card-title mb-0" style="text-align:center">
      {{cardData.cardTitle}}<span class="warning_icon" v-b-popover.hover.top="marketplaceFeeDetails">!</span>
    </div>

    <div v-else-if="cardData.cardTitle === 'Service Fee'" class="remitence-details-card-title mb-0" style="text-align:center">
      {{cardData.cardTitle}}<span class="warning_icon" v-b-popover.hover.top="serviceFeeDetails">!</span>
    </div>

    <div v-else-if="cardData.cardTitle === 'Miscellaneous Adjustment'" class="remitence-details-card-title mb-0" style="text-align:center">
      {{cardData.cardTitle}}<span class="warning_icon" v-b-popover.hover.top="'Detail of Miscellaneous Adjustment'">!</span>
    </div>

    <div v-else class="remitence-details-card-title mb-0" style="text-align:center">{{cardData.cardTitle}}</div>
    
    <div
      v-if="cardData.isMarketplaceFee" @click="openModal(modalTableDetails.marketplaceFee, 'Marketplace Fee', modalCurrency)"
      style="font-size:14px;color:#2e8cd3;font-weight: 600;font-family: Cabin;text-align:center; cursor:pointer"
      class="mt-1"
    >{{cardData.cardText}}
    </div>

    <div
      v-else-if="cardData.sevicedetail" @click="openModal(modalTableDetails.serviceFee, 'Service Fee', modalCurrency)"
      style="font-size:14px;color:#2e8cd3;font-weight: 600;font-family: Cabin;text-align:center; cursor:pointer"
      class="mt-1"
    >{{cardData.cardText}}
    </div>

    <div
      v-else
      style="font-size:14px;color:#1b1c1d;font-weight: 600;font-family: Cabin;text-align:center"
      class="mt-1"
    >{{cardData.cardText}}
    </div>

    <w-modal
      :trigger="showModal"
      :title="title"
      :centered="true"
      :showModalTable="true"
      :isServicefee="true"
      :modalTableDetails="modalTableData"
      :modalCurrency="modalCurrency"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :showEasyShip="showEasyShip"
      :showCancellation="showCancellation"
      @eventClose="closeModal()"
    />

  </div>
</template>

<script>
import Modal from "./ModalWidget";
export default {
  props: ["cardData","modalTableDetails","modalCurrency"],
  components: {
    "w-modal": Modal
  },
  data(){
    return {
      title: "",
      modalCurrency: "",
      showModal: false,
      marketplaceFeeDetails: "This includes FBA Inbound Transportation Fee, Storage Fee, Disposal Complete.",
      serviceFeeDetails: "This includes  Cost of Advertising",
      modalTableData: {}
    }
  },
  methods: {
    
    openModal(event,title, modalCurrency) {
      this.showModal = true,
      this.modalTableData = { tableData:event },
      this.title= title,
      this.modalCurrency = modalCurrency
    },
    closeModal() {
      this.showModal = false;
    },
    getStyleBorder() {
      switch (this.cardData.cardStyle) {
        case "Channel":
          return "border-left:4px solid #1d408e;";
        case "Date&Time":
          return "border-left:4px solid #f843ae;";
        case "PreviousBalance":
          return "border-left:4px solid #2698E3;";
        case "AmountPaid":
          return "border-left:4px solid #6560fd;";
        case "CarryoverBalance":
          return "border-left:4px solid #A13040;";
        case "NumberofOrders":
          return "border-left:4px solid #7c2bf4;";

        case "payableToAmazon":
          return "border-left:4px solid #CC2EFA;";
        case "serviceFee":
          return "border-left:4px solid #2E2E2E;";
        case "marketplaceFee":
          return "border-left:4px solid #B43104;";
        case "miscAdjustment":
          return "border-left:4px solid #0B3B0B;";
      }
    }
  }
};
</script>

<style scoped>
::v-deep .popover-body{
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  color: #1b1c1d;
  font-weight: 300;
}
.remitence-details-card {
  background-color: #ffffff;
  height: 5rem;
  border-radius: 0px;
}
.remitence-details-card .warning_icon {
  border: 1px solid #B40404;
  border-radius: 50%;
  font-size: 7px;
  padding: 1px 4px;
  color: #B40404;
  vertical-align: middle;
  margin-left: 6px;
  cursor: pointer;
}
.remitence-details-card-title {
  color: #1b1c1d;
  opacity: 0.6;
  font-size: 15px !important;
  font-weight: 600;
  font-family: Cabin;
  /* cursor: pointer; */
}
  /* fba table style */
.fba-fee-table{
  width: 250px;
  background: #efefef;
}
.fba-fee-table table {
  border-collapse: collapse;
  width: 100%;
  background: #efefef;
}
.fba-fee-table table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  color: #1b1c1d;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
}
@media (max-width: 1100px) {
  .remitence-details-card-title {
    font-size: 13px !important;
  }
}
/* tooltip style */
::v-deep .popover-body{
  background-color: #D6EAF8   !important;
    border: 1px solid #D6EAF8;
    border-radius: 0.3rem;
}
</style>