import { Apihelper } from "./API/apiHelper.js";
const apiHelper = new Apihelper();
const baseUrl = process.env.VUE_APP_ORDER_SERVER_URL;

export const getOrderById = (companyId = 7, channelId = 1, masterOrderId = 1) => {
    const uri = `${baseUrl}/api/v1/companies/channels/${channelId}/orders/${masterOrderId}`;
    return apiHelper.get(uri);
}

export const getTypes = () => {
    const uri = `${baseUrl}/api/v1/orders/fulfilled-by`;
    return apiHelper.get(uri);
}

export const getPaymentTypes = () => {
    const uri = `${baseUrl}/api/v1/orders/payment-type`;
    return apiHelper.get(uri);
}

export const getOrderListWithFilter = (pageNumber = 0, pageSize = 2, startDate, endDate, channelIds, shipmentTypes, shipmentStatus, fullfilmentTypes, orderId, sortType, orderStatus) => {
    let uri = `${baseUrl}/api/v1/companies/orders?page-no=${pageNumber}&page-size=${pageSize}`;
    if (startDate) {
        uri = uri.concat('&start-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&end-date=', endDate);
    }
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-ids=', channelIds);
    }
    if (shipmentTypes && shipmentTypes.length > 0) {
        uri = uri.concat('&shipment-types=', shipmentTypes);
    }
    if (shipmentStatus && shipmentStatus.length > 0) {
        uri = uri.concat('&shipment-status=', shipmentStatus);
    }
    if (fullfilmentTypes && fullfilmentTypes.length > 0) {
        uri = uri.concat('&fulfillment-types=', fullfilmentTypes);
    }
    if (orderId != '') {
        uri = uri.concat('&order-id=', orderId);
    }
    if (sortType && sortType.length > 1) {
        uri = uri.concat('&sort=', sortType);
    }
    if (orderStatus && orderStatus != "All") {
        uri = uri.concat('&order-status=', orderStatus);
    }
    return apiHelper.get(uri);
}

export const getOrderDetailsById = (orderId, channelId, companyId) => {
    // const uri = `${baseUrl}/api/v1/companies/channels/${channelId}/orders/${orderId}`;
    const uri = `${baseUrl}/api/v1/companies/channels/${channelId}/orders-info/${orderId}`;
    return apiHelper.get(uri);
}
export const downloadOrder = (orderStatus, orderId, sortType, shipmentType, shipmentStatus, fullfilmentTypes, channelIds, startDate, endDate, companyId) => {
    let uri = `${baseUrl}/api/v1/companies/download-orders?`;
    if (orderId != '') {
        uri = uri.concat('&order-id=', orderId);
    }
    if (sortType && sortType.length > 1) {
        uri = uri.concat('&sort=', sortType);
    }
    if (shipmentType && shipmentType.length > 0) {
        uri = uri.concat('&shipment-types=', shipmentType);
    }
    if (shipmentStatus && shipmentStatus.length > 0) {
        uri = uri.concat('&shipment-status=', shipmentStatus);
    }
    if (fullfilmentTypes && fullfilmentTypes.length > 0) {
        uri = uri.concat('&fulfillment-types=', fullfilmentTypes);
    }
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-id=', channelIds);
    }
    if (startDate) {
        uri = uri.concat('&start-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&end-date=', endDate);
    }
    if (orderStatus && orderStatus != "All") {
        uri = uri.concat('&order-status=', orderStatus);
    }

    return apiHelper.downloadFile(uri);
}

export const getOrderCount = () => {
    const uri = `${baseUrl}/api/v1/companies/order-count`;
    return apiHelper.get(uri);
}

//Shipment status list
export const getShipmentStatus = () => {
    const uri = `${baseUrl}/api/v1/orders/shipment-status`;
    return apiHelper.get(uri);
}

//Shipment types and used return section also
export const getShipmentTypes = () => {
    const uri = `${baseUrl}/api/v1/orders/shipment-type`;
    return apiHelper.get(uri);
}

export const createShipment = (data, companyId) => {
    const uri = `${baseUrl}/api/v1/companies/order-shipment-batches`; // changed  ( warehouse id is hardcoded now)
    return apiHelper.post(uri, data);
}

// for Return section in Orders

// get return status
export const getReturnStatus = () => {
    const uri = `${baseUrl}/api/v1/return-statuses`;
    return apiHelper.get(uri);
}

// get return landing table list 

export const getReturnListWithFilter = (orderId, status, channelIds, shipmentType, paymentTypes, startDate, endDate, pageNumber = 0, pageSize = 2, companyId) => {
    // let uri = `${baseUrl}/api/v1/returns?company-id=${companyId}&page-no=${pageNumber}&page-size=${pageSize}`;
    let uri = `${baseUrl}/api/v1/returns?page-no=${pageNumber}&page-size=${pageSize}`;
    if (startDate) {
        uri = uri.concat('&start-purchase-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&end-purchase-date=', endDate);
    }
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-ids=', channelIds);
    }
    if (paymentTypes && paymentTypes.length > 0) {
        uri = uri.concat('&payment-types=', paymentTypes);
    }
    if (orderId != '') {
        uri = uri.concat('&order-id=', orderId);
    }
    if (status) {
        uri = uri.concat('&return-statuses=', status);
    }
    if (shipmentType) {
        uri = uri.concat('&shipment-types=', shipmentType);
    }

    return apiHelper.get(uri);
}

// for download returns order
export const downloadReturns = (orderId, statusIds, channelIds, shipmentType, paymentType, startDate, endDate) => {
    // let uri = `${baseUrl}/api/v1/returned-orders?company-id=${companyId}`;
    let uri = `${baseUrl}/api/v1/returned-orders?`;
    if (orderId != '') {
        uri = uri.concat('&order-id=', orderId);
    }
    if (statusIds && statusIds.length > 0) {
        uri = uri.concat('&return-statuses=', statusIds);
    }
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-ids=', channelIds);
    }
    if (shipmentType && shipmentType.length > 0) {
        uri = uri.concat('&shipment-types=', shipmentType);
    }
    if (paymentType && paymentType.length > 0) {
        uri = uri.concat('&payment-types=', paymentType);
    }
    if (startDate) {
        uri = uri.concat('&start-purchase-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&end-purchase-date=', endDate);
    }

    return apiHelper.downloadFile(uri);
}