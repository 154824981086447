import { render, staticRenderFns } from "./RemittenceCollapseTable.vue?vue&type=template&id=350b13a6&scoped=true&"
import script from "./RemittenceCollapseTable.vue?vue&type=script&lang=js&"
export * from "./RemittenceCollapseTable.vue?vue&type=script&lang=js&"
import style0 from "./RemittenceCollapseTable.vue?vue&type=style&index=0&lang=css&"
import style1 from "./RemittenceCollapseTable.vue?vue&type=style&index=1&id=350b13a6&scoped=true&lang=css&"
import style2 from "./RemittenceCollapseTable.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "350b13a6",
  null
  
)

export default component.exports