<template>
  <div class="whithout-tab-content">

<!-- individual product details start -->
    <w-individual-products
      v-if="showIndividualProduct == true"
      :masterSku="masterSku"
      @backfromproduct="backfromproduct($event)"
    />
<!-- end -->
<!-- shipment order details end -->
    <b-card class="m-2 shipment-details" style="box-shadow: 0px 1px 3px #00000029;" v-if="shipmentTabs">
        <div class="row p-0 m-0">
          <div
            class="col-lg-2 col-md-2 col-6 p-0 m-0 detail-column"
            v-for="tab in shipmentTabs"
            :key="tab"
          >
            <span class="shipment-heading-class">{{
              tab.text
            }}</span
            ><br />
            <span class="shipment-value-class">{{
              tab.value
            }}</span>
          </div>
        </div>
    </b-card>
  <!-- shipment order details -->

    <div class="row my-2 mx-2 align-items-center" v-if="showContent">
      <div
        class="back-button"
        @click="goBackToPreviousPage()"
      >
        <img src="../assets/SVG/Back.svg" width="60px">
      </div>
      <!-- navigataion link start-->
      <div class="ml-2" v-if="shipmentTabs">
        <span class="link-one" @click="backToShipment"
          >Shipments</span
        >
        <span class="mx-2 link-arrow">&#8250;</span>
        <span class="link-two">Shipment Details</span>
      </div>
    <!-- navigataion link end-->
    </div>

    <div class="row mx-2 mb-3" v-if="showContent">
      <div class="col-md-6 col-12">
        <div class="row mb-4 py-4 order-details" >
          <div class="d-flex w-100 pl-4 mb-1" v-for="(item, index) in orderDetails" :key="index">
            <div class="col-md-3 col-3 py-1">
              <div class="order-key">{{ item.key }}</div>
            </div>
            <div
              class="col-md-8 col-8 py-1 order-value"
              v-if="item.key == 'Status'"
            >
              <div>:<span class="ml-3">{{ item.value }}</span></div>
            </div>
            <div class="col-md-8 col-8 py-1" v-else>
              <div class="order-value">:<span class="ml-3">{{ item.value }}</span></div>
            </div>
          </div>
        </div>

        <div class="row order-details" style="box-shadow: 0px 3px 6px #00000029;">
          <div class="order-table w-100">
            <NormalTable
              :orderDetailsTableData="orderItemsDetailsTableData"
              @goToProduct="individualProduct($event)"
            />
          </div>
        </div>

      </div>
      
      <div class="col-md-6 col-12 py-4 buyer-details">
        <div
          class="row pl-3"
          v-for="(item, index) in buyerDetails"
          :key="index"
        >
          <div class="col-md-3 col-3 order-key">{{ item.key }}</div>
          <div class="col-md-8 col-8 order-value" style="word-break:break-all">:<span class="ml-3">{{ item.value }}</span></div>
        </div>

        <hr class="my-5" style="border-color:#6D6F72">

        <div
          :class="[{ 'font-weight-bold': item.key === 'Order Total'},'row pl-3']"
          v-for="(item, index) in priceDetails"
          :key="index"
        >
          <div class="col-md-8 col-8 order-key">{{ item.key }}</div>
          <div class="col-md-3 col-3 order-value d-flex justify-content-between"><span>:</span><span :class="{'font-weight-normal': item.key !== 'Order Total'}">{{ item.value }}</span></div>
        </div>
      </div>
    </div>

    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
  </div>
</template>

<script>
import moment from "moment";
import moment_timezone from "moment-timezone";
import Modal from "./ModalWidget";
import NormalTable from "./NormalTable.vue";
import IndividualProducts from "../components/Inventories/IndividualProducts";
import { getOrderDetailsById } from "../Service/OrderService";

export default {
  components: {
    Modal,
    NormalTable,
    "w-individual-products": IndividualProducts,
  },
  data() {
    return {
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      showContent: false,
      orderDetails: [],
      buyerDetails: [],
      priceDetails:[],
      masterSku: null,
      showIndividualProduct: false,
      orderItemsDetailsTableData: {
        items: [],
        fields: [
          {
            key: "Channel SKU",
            label: "Channel SKU",
          },
          {
            key: "SKU",
            label: "SKU",
          },
          {
            key: "Title",
            label: "Title",
           
          },
          {
            key: "Quantity",
            label: "Quantity",
          },
          {
            key: "Item Cost",
            label: "Item Cost",
          },
          {
            key: "Item Tax",
            label: "Item Tax",
          },
        ],
      },
    };
  },
  created() {
    this.goToOrderDetailsPage();
  },
  props: ["orderIdParams", "channelIdParams", "companyIdParams", "shipmentTabs"],
  methods: {
    goBackToPreviousPage() {
      this.$emit("gotoBackPage");
    },
    
    goToOrderDetailsPage() {
      this.showContent = false;
      var time = new Date();
      var timeZoneOffset = time.getTimezoneOffset();
      var timeZone = moment_timezone.tz.guess();
      timeZone = moment_timezone.tz.zone(timeZone).abbr(timeZoneOffset)
      getOrderDetailsById(
        this.orderIdParams,
        this.channelIdParams,
        this.companyIdParams
      )
        .then((res) => {
          if (!res.errorCode) {
            this.orderDetails = [];
            this.buyerDetails = [];
            this.priceDetails = [];
            this.orderItemsDetailsTableData.items = [];
            this.showContent = true;
            let resp = res.response;
            this.orderItemsDetailsTableData.items = resp.itemList;
            // console.log(" order ",resp)

            // for order details
            for (let order in resp.orderShortDetailDto) {
              if(order === "Purchase Date"){
                resp.orderShortDetailDto[order] = `${this.getFormatDate(resp.orderShortDetailDto[order])}`;           
              }
              if((order === "Ship By" || order === "Deliver By") && resp.orderShortDetailDto[order] !== ""){
                var dateAndTime = resp.orderShortDetailDto[order].split(" to ");
                resp.orderShortDetailDto[order] = `${this.getFormatDate(dateAndTime[0])} to ${this.getFormatDate(dateAndTime[1])}`
              }
              this.orderDetails.push({
                key: order,
                value: resp.orderShortDetailDto[order],
              });
            }
            
            // for buyer details
            for (let buyer in resp.buyer) {
              this.buyerDetails.push({
                key: buyer,
                value: resp.buyer[buyer],
              });
            }
            // for price details
            for (let price in resp.orderPriceDto) {
              this.priceDetails.push({
                key: price,
                value: resp.orderPriceDto[price],
              });
            }
            
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    getFormatDate(dateAndTime){
      let localDateAndTime = moment.utc(dateAndTime,'DD-MM-YYYY hh:mm:ss').local().format('DD/MM/YYYY hh:mm A');
      return localDateAndTime;
    },
    individualProduct(event) {
      this.showIndividualProduct = true;
      this.showContent = false;
      this.masterSku = event;
    },
    backfromproduct(event) {
      this.showIndividualProduct = event;
      this.showContent = true;
    },
    // for navigation
    backToShipment() {
      this.$router.push("/shipment");
    },

  // modal close
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
  },
};
</script>

<style scoped>
.back-button{
  cursor: pointer;
}
.link-one, .link-two{
  font-size:11px;
  color:#2185D0;
  cursor:pointer;
  font-family: "roboto-regular";
}
.link-two{
  font-size: 13px;
  font-family: "roboto-bold";
  cursor:default;
}
.link-arrow{
  color: #2185D0;
  font-size: 20px;
}
.striped-class {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.non-striped-class {
  background-color: #ffffff !important;
}
.order-heading {
  font-size: 14px;
  font-weight: bold;
  color: #302e2e;
}
.order-details{
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  background: #fff;
}
.order-key{
  font-size: 13px;
  font-family: "roboto-regular";
  color: #6D6F72;
}
.order-value{
  font-size: 13px;
  color: #1B1C1D;
  font-family: "proxima-nova", sans-serif;
  font-weight: bold;
  opacity: 0.8;
}
.buyer-details{
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
  border-radius: 5px;
}
.shipment-details ::v-deep .card-body{
  padding-left: 40px !important;
}
.shipment-heading-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 11px;
  color: #606060;
}
.shipment-value-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  font-weight: bold;
  color: #1B1C1D;
}
.order-details{
  margin-right: 12px;
}
.order-table {
  /* background: #fff;
  border-radius: 5px; */
  max-width: 100%;
}
.order-table ::v-deep table {
  margin-bottom: 0;
}
.order-table ::v-deep th {
  vertical-align: middle;
}
.order-table ::v-deep tbody {
  white-space: nowrap;
}
.order-table ::v-deep .table thead tr {
  text-align: center;
}
.order-table ::v-deep .table tbody tr td {
  text-align:center;
}

@media screen and (max-width : 767.98px) {
  .order-value{
    word-break: break-all;
  }
  .order-details{
    margin-bottom: 1rem;
    margin-right: -12px !important;
  }
}
@media screen and (max-width: 800px){
  .detail-column div:nth-child(even){
    padding-left: 40px !important;
  }
}
</style>
