<template>
  <div class="mt-3">
    <div class="last-update-wrapper">
      <span class="ml-2 summary_refresh_text mt-2"
        >Last Updated On: {{ lastUpdated ? lastUpdated : "-" }}</span
      >&nbsp;&nbsp;<span class="summary_refresh">
        <img
          style="cursor:pointer"
          @click="loadData()"
          src="../../assets/refresh.png"
          width="15px"
        />
      </span>
    </div>
    <b-card class="m-2 remittance-filter" :body-class="'card-padding'" v-if="isDesktopFilterOpen">
      <img class="mt-3 close-filter-icon" src="../../assets/CloseModel.svg" width="15px" style="cursor:pointer" @click="closeDesktopFilter">
      <div class="row">
        <div class="col-md-3">
          <label class="tree-label">Remittance Date</label>
          <date-picker
            v-model="selectedPurchaseDate"
            :lang="'en'"
            range
            confirm
            :shortcuts="[]"
            @clear="clearPurchaseDate($event)"
            @confirm="getPurchaseDate($event)"
          ></date-picker>
        </div>
        <div class="col-md-3">
          <w-tree-dropdown
            :labelText="'Channels'"
            :rootOptions="channelListOptions"
            :selectedValue="channelListValues"
            @selectedField="selectedChannelsCategory($event)"
          />
        </div>
        <div class="col-md-3">
          <w-tree-dropdown
            :labelText="'Payment Type'"
            :rootOptions="typeListOptions"
            :selectedValue="typeListValues"
            @selectedField="selectedTypeCategory($event)"
          />
        </div>
      </div>
    </b-card>
    
    
    <div class="remittance-table">
      <w-table
        :tableProperties="tableProperties"
        :tableLoading="tableLoading"
        @downloadEvent="downloadEventCSV($event)"
        @getSelectedPageEvent="getSelectedPage($event)"
        @getSelectedPerPageEvent="getSelectedPerPage($event)"
        @getIndividualRemittenceDetails="getIndividualRemittenceDetails($event)"
        :isFilterButton="true"
        @openDesktop_filterEvent="openDesktop_filter"
      />
    </div>
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
    <w-loading-spinner :loadSpinner="loadingWidget" />
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import Modal from "../../widgets/ModalWidget";
import LoadingSpinner from "../../widgets/LoadingSpinner";
import TreeDropdown from "../../widgets/TreeDropdown";
import TableWithCheckbox from "../../widgets/TableWithCheckbox.vue";
import {
  getPaymentTypes,
  getRemittenceTabListWithFilter,
  getDownloadRemittance,
} from "../../Service/RemittenceService";

export default {
  components: {
    Modal,
    DatePicker,
    "w-table": TableWithCheckbox,
    "w-tree-dropdown": TreeDropdown,
    "w-loading-spinner": LoadingSpinner,
  },
  props: ["channelListOptions", "isGetData"],
  data() {
    return {
      isDesktopFilterOpen:false,
      companyID:sessionStorage.getItem("companyIds"),
      alertType: "",
      alert: false,
      alertMsg: "",
      loadingWidget: false,
      isConfirmAlert: false,
      selectedPurchaseDate: "",
      channelListValues: [],
      typeListValues: [],
      typeListOptions: [],
      tableLoading: false,
      tableProperties: {
        fields: [
          {
            key: "channelName",
            label: "Channel",
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "settlementPeriod",
            label: "Settlement Period",
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "remittanceDate",
            label: "Remittance date",
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "paymentType",
            label: "Payment Type",
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "previousReserveAmount",
            label: "Previous Balance",
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "remittedAmount",
            label: "Remitted Amount",
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "currencyReserveAmount",
            label: "Carry Over Balance",
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "numberOfOrders",
            label: "No. of Orders",
            thClass: "thClass",
            tdClass: "tdClass",
          },
          {
            key: "remitence_Action",
            label: "Action",
            thClass: "thClass",
            tdClass: "tdClass",
          },
        ],
        items: [],
        perPage: 50,
        currentPage: 1,
        totalRows: 0,
        showButton: false,
      },
      lastUpdated: "",
    };
  },
  created() {
    // default last 30 days

    // let first = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    // first = new Date(first.setHours(0, 0, 0, 0));
    // let last = new Date();
    // last = new Date(last.setHours(0, 0, 0, 0));
    // this.selectedPurchaseDate = [first, last];
    // //
    // this.getPaymentTypes();
    // this.loadData();
    if(this.isGetData){
      this.getData();
    }
  },
  methods: {
    getData(){
      let first = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
      first = new Date(first.setHours(0, 0, 0, 0));
      let last = new Date();
      last = new Date(last.setHours(0, 0, 0, 0));
      this.selectedPurchaseDate = [first, last];
      this.getPaymentTypes();
      this.loadData();
    },

    loadData() {
      this.getLastUpdated();
      this.getRemittenceTabTableList();
    },
    // To get  last updated time
    getLastUpdated() {
      this.lastUpdated = moment().format("MM/DD/YYYY hh:mm:ss a");
    },
    getSelectedPage(currentPage) {
      this.tableProperties.currentPage = currentPage;
      this.getRemittenceTabTableList();
    },
    getSelectedPerPage(perPage) {
      this.tableProperties.perPage = perPage;
      this.tableProperties.currentPage = 1;
      this.getRemittenceTabTableList();
    },
    clearPurchaseDate() {
      this.startDate = "";
      this.endDate = "";
      this.getRemittenceTabTableList();
    },
    getPurchaseDate() {
      this.startDate = moment(this.selectedPurchaseDate[0]).valueOf();
      this.endDate = moment(this.selectedPurchaseDate[1]).valueOf();
      this.getRemittenceTabTableList();
    },
    selectedChannelsCategory(event) {
      this.channelListValues = event;
      this.getRemittenceTabTableList();
    },
    getPaymentTypes() {
      getPaymentTypes()
        .then((res) => {
          if (!res.errorCode) {
            let resp = res.response;
            resp.forEach((element) => {
              this.typeListOptions.push({
                id: element,
                label: element,
              });
            });
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    selectedTypeCategory(event) {
      this.typeListValues = event;
      this.getRemittenceTabTableList();
    },
    downloadEventCSV(event) {
      this.loadingWidget = true;
      getDownloadRemittance(event.channelId, event.reportId, this.companyID).then((res) => {
        this.loadingWidget = false;
      });
    },
    getRemittenceTabTableList() {
      this.tableLoading = true;
      this.tableProperties.items = [];
      getRemittenceTabListWithFilter(
        this.tableProperties.currentPage - 1,
        this.tableProperties.perPage,
        this.startDate,
        this.endDate,
        this.channelListValues,
        this.typeListValues
      )
        .then((res) => {
          this.tableLoading = false;
          console.log("remittance detail", res);
          if (!res.errorCode) {
            let resp = res.response;
            this.tableProperties.items = resp.content || [];
            this.tableProperties.items.forEach((item) => {
              item.selected = false;
            });
            this.tableProperties.totalRows = resp.totalElements;
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    getIndividualRemittenceDetails(event) {
      this.$emit("showBackButtonInTab", event);
    },
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    // for open a desktop filter
    openDesktop_filter(){
      this.isDesktopFilterOpen = !this.isDesktopFilterOpen;
    },
    closeDesktopFilter(){
      this.isDesktopFilterOpen=false;
    }
  },
};
</script>

<style scoped>
.card-body{
  display: flex;
}
/* .card-padding {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  padding-top: 0 !important;
} */
.show-class {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  line-height: 2;
  text-align: left;
  color: #767676;
}
.remittance-table{
  position: relative;
  z-index: 1;
}
.remittance-table ::v-deep table thead tr th {
  text-align: center !important;
}
.remittance-table ::v-deep table tbody tr td {
  text-align: center !important;
}
.remittance-table ::v-deep table thead tr th:first-child {
  text-align: left !important;
}
.remittance-table ::v-deep table tbody tr td:first-child {
  text-align: left !important;
}
.remittance-table ::v-deep tbody tr{
  white-space: nowrap;
}

@media only screen and (max-width: 768px){
  .remittance-filter ::v-deep .mx-range-wrapper {
    width: 100%;
  }
  .remittance-filter ::v-deep .mx-calendar {
    width: 100%;
  }
  .remittance-filter ::v-deep .mx-calendar-content {
    width: 100%;
  }
  .remittance-filter ::v-deep .mx-datepicker-popup {
    left: 0px !important;
  }
}

@media screen and (max-width:780px){
  .last-update-wrapper{
    margin-top: 2rem;
  }
}

.close-filter-icon{
  cursor: pointer;
  position: absolute;
  right: 10px;
  z-index: 2;
  top:0;
}
</style>
