import { Apihelper } from "./API/apiHelper.js";
const apiHelper = new Apihelper();
const remittenceBaseUrl = process.env.VUE_APP_REMITTENCE_SERVER_URL;
const baseBrandsListingUrl = process.env.VUE_APP_LISTING_PRODUCT_SERVER_URL;
const baseDateListUrl = process.env.VUE_APP_ORDER_SERVER_URL;
const baseChannelListUrl = process.env.VUE_APP_CHANNEL_SERVER_URL;

export const getPaymentTypes = () => {
    const uri = `${remittenceBaseUrl}/api/v1/remittance/payment-type`;
    return apiHelper.get(uri);
}

export const getRemittenceTabListWithFilter = (pageNumber, pageSize, startDate, endDate, channelIds, paymentTypes) => {
    let uri = `${remittenceBaseUrl}/api/v1/remittance-report?page-no=${pageNumber}&page-size=${pageSize}`;
    if (startDate) {
        uri = uri.concat('&remittance-start-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&remittance-end-date=', endDate);
    }
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-id=', channelIds);
    }
    if (paymentTypes && paymentTypes.length > 0) {
        uri = uri.concat('&payment-type=', paymentTypes);
    }
    return apiHelper.get(uri);
}

export const getRemittenceIndividualDetails = (pageNumber, pageSize, settlementID, remittanceID, startDate, endDate, orderID, channelOrderID) => {
    let uri = `${remittenceBaseUrl}/api/v1/remittance-report-order?page-no=${pageNumber}&page-size=${pageSize}&settelement-id=${settlementID}&report-id=${remittanceID}`;
    if (startDate) {
        uri = uri.concat('&purchase-start-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&purchase-end-date=', endDate);
    }
    if (orderID) {
        uri = uri.concat('&order-id=', orderID);
    }
    if (channelOrderID) {
        uri = uri.concat('&channel-order-id=', channelOrderID);
    }
    return apiHelper.get(uri);
}

export const getDownloadRemittance = (channelId, reportId, companyId) => {
    const uri = `${remittenceBaseUrl}/api/v1/companies/channels/${channelId}/reports/download/${reportId}`;
    return apiHelper.downloadRemittanceFile(uri);
}

// integration for SKU-Wise remittence

export const getBrandsListing = (companyId) => {
    const uri = `${baseBrandsListingUrl}/api/v1/companies/channels/products/brands`;
    return apiHelper.get(uri);
}

export const getPerformanceList = () => {
    const uri = `${remittenceBaseUrl}/api/v1/remittance-skuwise-report/performances`;
    return apiHelper.get(uri);
}

// get sku-wise remittence table data with filter

export const getSkuWiseListWithFilter = (pageNumber, pageSize, startDate, endDate, channelIds, brandsIds, performance, dateRange, companyId) => {

    let uri = `${remittenceBaseUrl}/api/v1/remittance-skuwise-report?page-size=${pageSize}&page-no=${pageNumber}`

    if (startDate) {
        uri = uri.concat('&purchase-start-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&purchase-end-date=', endDate);
    }
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-id=', channelIds);
    }
    if (brandsIds && brandsIds.length > 0) {
        uri = uri.concat('&brand=', brandsIds);
    }
    if (performance) {
        uri = uri.concat('&performance=', performance);
    }
    if (dateRange) {
        uri = uri.concat('&date-range=', dateRange);
    }

    return apiHelper.get(uri);
}

// download SKU wise remittance 

export const downloadSkuWiseRemittance = (startDate, endDate, channelIds, brandsIds, performance, dateRange, companyId) => {
    let uri = `${remittenceBaseUrl}/api/v1/remittance/download-sku?`;

    if (startDate) {
        uri = uri.concat('&purchase-start-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&purchase-end-date=', endDate);
    }
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-id=', channelIds);
    }
    if (brandsIds && brandsIds.length > 0) {
        uri = uri.concat('&brand=', brandsIds);
    }
    if (performance) {
        uri = uri.concat('&performance=', performance);
    }
    if (dateRange) {
        uri = uri.concat('&date-range=', dateRange);
    }

    return apiHelper.downloadFile(uri);
}

// REMITTED STATUS OF ORDERS

export const getRemittedStatusOrder = (pageNumber, pageSize, startDate, endDate, problemReason, channelIDs, orderID, companyId) => {
    let uri = `${remittenceBaseUrl}/api/v1/remittance-orderwise-report?page-no=${pageNumber}&page-size=${pageSize}`;

    if (startDate) {
        uri = uri.concat('&purchase-start-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&purchase-end-date=', endDate);
    }
    if (problemReason && problemReason.length > 0) {
        uri = uri.concat('&problem=', problemReason);
    }
    if (channelIDs && channelIDs.length > 0) {
        uri = uri.concat('&channel-id=', channelIDs);
    }
    if (orderID != '') {
        uri = uri.concat('&orderId=', orderID);
    }

    return apiHelper.get(uri);
}

export const getRemittanceProblem = () => {
    const uri = `${remittenceBaseUrl}/api/v1/remittance-orderwise-report/remittanceproblem`;

    return apiHelper.get(uri);
}

// REMITTANCE DASHBOARD

export const getRemittanceCardData = (startDate, endDate, channelIds, companyId) => {
    let uri = `${remittenceBaseUrl}/api/v1/remittance-dashboard?page-no=0&page-size=50`;

    if (startDate) {
        uri = uri.concat('&purchase-start-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&purchase-end-date=', endDate);
    }
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-id=', channelIds);
    }

    return apiHelper.get(uri);
}

export const getChannelList = (companyId) => {
    const uri = `${baseChannelListUrl}/api/v1/companies/channels`;
    return apiHelper.get(uri);
};