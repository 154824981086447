<template>
  <div class="mb-3 container" id="inventory-table">
        <div class="row">
          <b-col class="col-md-12 py-0">
            <!--<b-row >
               <div class="ml-3 mt-2 pb-2">
                <span class="product-Id" v-if="individualProductDetails.masterSku">
                  {{ individualProductDetails.masterSku }}&nbsp;
                  <span class="slash">&nbsp;/&nbsp;</span>
                </span>
                <span
                  class="sub-title"
                  style="font-size:14px !important"
                >&nbsp;{{ individualProductDetails.title }}</span>
              </div>
            </b-row>-->
            <div class="row d-flex">
              <!-- <b-col>
                <p class="sub-title">SKU</p>
                <p class="sub-value">{{ individualProductDetails.sku }}</p>
              </b-col>
              <b-col>
                <p class="sub-title">Category</p>
                <p class="sub-value">{{ individualProductDetails.category }}</p>
              </b-col>
              <b-col>
                <p class="sub-title">Brand</p>
                <p class="sub-value">{{ individualProductDetails.brand }}</p>
              </b-col>
              <b-col>
                <p class="sub-title">Alias</p>
                <p class="sub-value">{{ individualProductDetails.alias }}</p>
              </b-col>-->
              <b-col>
                <p class="sub-title">Sellable</p>
                <p class="sub-value">{{ individualProductDetails.sellerSellableQuantity }}</p>
                <p
                  style="font-size:11px"
                  class="my-0"
                >Seller: {{ individualProductDetails.sellerSellableQuantity }}</p>
                <p
                  style="font-size:11px"
                  class="my-0"
                >Marketplace: {{ individualProductDetails.marketplaceSellableQuantity }}</p>
              </b-col>
              <b-col>
                <p class="sub-title">Bad</p>
                <p class="sub-value">{{ individualProductDetails.badQuantity }}</p>
              </b-col>
              <b-col>
                <p class="sub-title">Reserved</p>
                <p class="sub-value">{{ individualProductDetails.reservedQuantity }}</p>
              </b-col>
            </div>
          </b-col>
          <!-- <b-col class="col-md-2" style="border-left:1px solid #e0e0e0">
            <p class="set-stock-buuton" @click="inventorySettings($event)">Inventory Settings</p>
            <p class="set-stock-buuton" @click="inventoryLedger($event)">Inventory Ledger</p>
            <p class="set-stock-buuton" @click="activityLogs($event)">Activity Logs</p>
          </b-col>-->
        </div>
  </div>
</template>

<script>
export default {
  props: ["individualProductDetails"],
  data() {
    return {};
  },
  methods: {
    getImgUrl() {
      // var imagess = require.context("../assets/", false, /\.jpg$/);
      if (this.piccname != undefined) {
        var images = require.context("../assets/", false, /\.jpg$/);
        return images("./" + this.piccname + ".jpg");
      }

      // var imagess = this.piccname;
      // console.log(this.piccname);
      // return imagess;
    },
    infoclick() {},
    inventorySettings(event) {
      this.$emit("gotoSetting", true);
    },
    inventoryLedger(event) {
      this.$emit("gotoLedger", true);
    },
    activityLogs(event) {
      this.$emit("goToLogs", true);
    }
    //   seller(event){
    //     this.$emit("gotoSeller", true)
    //   },
    //   marketplace(event){
    //     this.$emit("gotoMarketplace", true)
    //   },
    //   goToProduct(event){
    //    this.$emit("goToProduct", true)
    //   },
    //  open(event){
    //  console.log(event.target.innerText)
    //  this.seller=event.target.innerText==="Seller"?true:false
    //  this.markett=event.target.innerText==="Marketplace"?true:false
    // }
  }
};
</script>

<style scoped>
/* #inventory-table .prod-image {
  width: 92px;
  height: 90px;
  margin-top: 26px;
} */
/* .table-div {
  display: flex;
  background-color: #ffffff;
  border-radius: 5px;
  border: none !important;
} */
.img-div {
  width: 155px;
  padding: 5px;
}
.sku-label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #2e8cd3;
}
.product-Id {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  color: #4a4a4a;
}
.slash {
  color: #e0e0e0;
}
.product-name {
  cursor: pointer;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #4a4a4a;
}
.container-1 {
  display: flex;
}
/* .sub-div {
  width: 100%;
} */
.sub-title {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #7b7b7b;

  margin-bottom: 0px;
}
.sub-value {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;
  margin-bottom: 0px;
}
.set-stock-buuton {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-decoration: underline;
  letter-spacing: normal;
  text-align: center;
  margin-top: 25px;
  color: #2e8cd3;
  cursor: pointer;
}
.sub-value-small {
  cursor: pointer;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2e8cd3;
  margin-bottom: 0px;
}
img {
  margin-right: 0px;
}
</style>
<style>
/* #inventory-table .custom-control {
  position: relative;
  top: 55px;
  left: 14px;
} */
</style>
