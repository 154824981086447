import { Apihelper } from "./API/apiHelper.js";
const apiHelper = new Apihelper();
const baseListingUrl = process.env.VUE_APP_LISTING_PRODUCT_SERVER_URL;
const baseWarehouseUrl = process.env.VUE_APP_WAREHOUSE_SERVER_URL;
const baseInventoryUrl = process.env.VUE_APP_INVENTORY_SERVER_URL;
const channelBaseUrl = process.env.VUE_APP_CHANNEL_SERVER_URL;

export const getListingProduct = (channelIds, sku, mappingStatusList, pageNumber = 0, pageSize = 10, productStatusList, companyId) => {
    let uri = `${baseListingUrl}/api/v1/companies/channels/products/listing-status?page-no=${pageNumber}&page-size=${pageSize}`;  
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-ids=', channelIds);
    }
    if (mappingStatusList && mappingStatusList.length > 0) {
        uri = uri.concat('&mapping-status=', mappingStatusList);
    }
    if (sku) {
        uri = uri.concat('&sku=', sku);
    }
    if (productStatusList && productStatusList.length > 0) {
        uri = uri.concat('&product-status=', productStatusList);
    }

    return apiHelper.get(uri);
}

export const getMappingStatus = () => {
    const uri = `${baseListingUrl}/api/v1/companies/channels/products/mapping-status`;
    return apiHelper.get(uri);
}

export const getListingProductStatus = (companyId) => {
    const uri = `${baseListingUrl}/api/v1/companies/channels/products/listing-status-values`;  
    return apiHelper.get(uri);
}

export const addProductMapping = (data, companyId) => {
    const uri = `${baseListingUrl}/api/v1/companies/channels/products/product-mapping`;  
    return apiHelper.post(uri, data);
}

export const createAlias = (aliasForm, companyId) => {
    const uri = `${baseListingUrl}/api/v1/companies/channels/products/create-alias`;  
    return apiHelper.post(uri, aliasForm);
}

export const getBrandsTypes = (companyId) => {
    const uri = `${baseListingUrl}/api/v1/companies/channels/products/brands`;  
    return apiHelper.get(uri);
}

export const getChannnelTypes = () => {
    const uri = `${channelBaseUrl}/api/v1/companies/channels`;  
    return apiHelper.get(uri);
}

export const getCategoriesTypes = (companyId) => {
    const uri = `${baseListingUrl}/api/v1/companies/channels/products/parent-category`;  
    return apiHelper.get(uri);
}

export const getWarehouseTypes = (companyId) => {
    const uri = `${baseWarehouseUrl}/api/v1/warehouses/name-and-id`;  
    return apiHelper.get(uri);
}

export const getStockFilterTable = (brand, channels, sku, title, pageNumber = 0, pageSize = 10, companyId) => {
    let uri = `${baseListingUrl}/api/v1/companies/channels/products?page-no=${pageNumber}&page-size=${pageSize}`;  
    
    if (brand && brand.length > 0) {
        uri = uri.concat('&brand=', brand);
    }
    if (channels && channels.length > 0) {
        uri = uri.concat('&channel-ids=', channels);
    }
    if (sku && sku.length > 0) {
        uri = uri.concat('&sku=', sku);
    }
    if (title && title.length > 0) {
        uri = uri.concat('&title=', title);
    }

    return apiHelper.get(uri);
}

export const getIndividualProduct = (masterSKU, companyId) => {
    const uri = `${baseListingUrl}/api/v1/companies/channels/products/product-details?master-sku=${masterSKU}`;  
    return apiHelper.get(uri);
}

// for seller link in inventory

export const getSellerDetail = (productId, companyId) => {
    const uri = `${baseInventoryUrl}/api/v1/companies/channels/products/${productId}/inventory/inventory-details?warehouse-type=SELLER`; 
    return apiHelper.get(uri);
}

// for marketPlace link in inventory

export const getMarketplacerDetail = (productId, companyId) => {
    const uri = `${baseInventoryUrl}/api/v1/companies/channels/products/${productId}/inventory/inventory-details?warehouse-type=MARKETPLACE`; 
    return apiHelper.get(uri);
}

// FOR UPLOAD TEMPLATE BUTTON IN LISTING STATUS

// Api for upload listing template table data
export const getUploadListingTable = (pageNumber = 0, pageSize = 2) => {
    const uri = `${baseListingUrl}/api/v1/bulk-mapping-details?page-no=${pageNumber}&size=${pageSize}`;
    return apiHelper.get(uri);
}
// api for upload template
export const uploadTemplate = (formData, companyId) => {
    const uri = `${baseListingUrl}/api/v1/product/upload/bulk-mapping?`;   
    return apiHelper.post(uri, formData);
  }
export const  downloadProcessingReport = (batchId) => {
    const uri = `${baseListingUrl}/api/v1/sku-bulk-mapping/download-process-report?batch-id=${batchId}`;
    return apiHelper.downloadFile(uri);
}
export const  downloadListingFile = (data) => {
    const uri = `${baseListingUrl}/api/v1/product/bulk-mapping/template/download`;
    return apiHelper.downloadListingFiles(uri, data);
}
// upload bulk stock
export const uploadBulkStock = (formData) => {
    const uri = `${baseInventoryUrl}/api/v1/inventories/upload/stock-update-template`;   
    return apiHelper.post(uri, formData);
}

export const updateStock = (isIncrement, isBad, payload) => {
    const uri = `${baseInventoryUrl}/api/v1/inventories/update/stock?is-increment=${isIncrement}&is-bad=${isBad}`;     
    return apiHelper.post(uri, payload);
}

export const inventoryHistory = (sku, page, size) => {
    const uri = `${baseInventoryUrl}/api/v1/inventories/bulk-stock/update-audit-details?sku=${sku}&page-no=${page}&size=${size}`;     
    return apiHelper.get(uri);
}