<template>
  <div v-if="!showInventoryHistory">
    <div class="row my-3">
      <b-button
        variant="primary"
        class="ml-auto mr-4 back-button"
        @click="goTStock()"
      >Back</b-button>
    </div>
    <div v-if="showContent">
      
      <w-setting v-if="Setting == true" @backfromSettings="backfromSettings($event)" />
      <w-ledger v-if="Ledger == true" @backfromLedger="backfromLedger($event)" />
      <w-logs v-if="Logs == true" @backfromLogs="backfromLogs($event)" />

      <div v-if="!Ledger && !Setting && !Logs">
        <div class="row m-2 mb-3 bg-white">
          <div class="w-100 m-2">
            <p class="font-weight-bold py-2 pl-3 mb-0" style="background:#efefef">Inventory Details</p>
          </div>
          <!-- <product-table
            :individualProductDetails="inventoryDetails"
            @gotoLedger="ledger($event)"
            @gotoSetting="setting($event)"
            @goToLogs="logs($event)"
          /> -->
          <w-table class="w-100 mt-n3" :tableProperties="inventoryDetails" @goToStockHistory="goToStockHistory($event)" @onSellerStockCLick="onSellerStockCLick($event)"></w-table>
        </div>
        <div class="row m-2 mb-3 bg-white">
          <div class="w-100 m-2">
            <p class="font-weight-bold py-2 pl-3 mb-0" style="background:#efefef">Product Details</p>
          </div>
          <div id="inventory-table" class="w-100">
            <div class="table-div pb-2">
              <div class="img-div">
                <div class="d-flex justify-content-between">
                  <div class="mx-auto">
                    <img width="90px" :src="productDetails.imageUrl" />
                    <!-- <img src="@/assets/Bootledesign.jpg" class="mt-5" width="150px" /> -->
                  </div>
                </div>
              </div>
              <div
                class="sub-div pt-2"
                style="border-left:1px solid #e0e0e0;padding-left: 15px;padding-right: 15px;"
              >
                <!-- <div class="row mt-1 ml-5 pb-2" style="border-bottom:1px solid #e0e0e0">
                <div class="row">
                  <div class="col-md-4">
                    <label class="mr-2 ml-2 detail_label">Selling Price</label>
                    <w-text-input
                      style="display:inline-block;"
                      :fieldType="'text'"
                      v-model="channelName"
                      :disabled="false"
                      :defaultValue="''"
                      :labelStyle="'register-label'"
                      @textInputChange="getFirstName($event)"
                    />
                  </div>

                  <div class="col-md-4">
                    <label class="mr-2 detail_label">Channel</label>
                    <w-dropdown
                      style="display:inline-block; width: 200px"
                      :label-text="''"
                      :selectedValue="null"
                      :selectOption="statusList"
                      @selectedOption="getSelectedStatus($event)"
                    />
                    <img src="@/assets/add.svg" class="add ml-2" style="display:inline-block;" />
                  </div>
                  <div class="col-md-4 text-center">
                    <b-button type="submit" class="save_back_btn" size="sm" @click="goTStock()">Save</b-button>
                  </div>
                </div>
                </div>-->
                <b-row style="border-bottom:1px solid #e0e0e0;font-weight:bold !important">
                  <div class="ml-3 mt-2 pb-2">
                    <span class="product-Id">
                      {{productDetails.masterSku}}&nbsp;
                      <span class="slash">&nbsp;/&nbsp;</span>
                    </span>
                    <span
                      class="sub-title"
                      style="font-size:14px !important;font-weight:bold !important"
                    >&nbsp;{{productDetails.title}}</span>
                  </div>
                </b-row>
                <b-row class="mt-2">
                  <b-col class="col-md-12 d-flex align-items-center">
                    <!-- <b-col>
                      <span class="sub-title" style="margin-left:42px">Dimension</span>
                    </b-col>-->
                    <label class="mr-2 mt-2 measurement">Length</label>
                    <w-text-input
                      style="display:inline-block; width:120px;"
                      :fieldType="'text'"
                      v-model="productDetailslength"
                      :disabled="true"
                      :defaultValue="productDetailslength"
                      :labelStyle="'register-label'"
                      @textInputChange="getFirstName($event)"
                    />
                    <span class="mr-3 pl-1 measure_unit">{{productDetails.dimensionUnit}}</span>
                    <label class="mr-2 mt-2 measurement">Width</label>
                    <w-text-input
                      style="display:inline-block; width:120px;"
                      :fieldType="'text'"
                      v-model="productDetailswidth"
                      :disabled="true"
                      :defaultValue="productDetailswidth"
                      :labelStyle="'register-label'"
                      @textInputChange="getFirstName($event)"
                    />
                    <span class="mr-3 pl-1 measure_unit">{{productDetails.dimensionUnit}}</span>
                    <label class="mr-2 mt-2 measurement">Height</label>
                    <w-text-input
                      style="display:inline-block; width:120px;"
                      :fieldType="'text'"
                      v-model="productDetailsheight"
                      :disabled="true"
                      :defaultValue="productDetailsheight"
                      :labelStyle="'register-label'"
                      @textInputChange="getFirstName($event)"
                    />
                    <span class="pl-1 mr-3 measure_unit">{{productDetails.dimensionUnit}}</span>
                    <label class="mr-2 mt-2 measurement">Weight</label>
                    <w-text-input
                      style="display:inline-block; width:120px;"
                      :fieldType="'text'"
                      v-model="productDetailsweight"
                      :disabled="true"
                      :defaultValue="productDetailsweight"
                      :labelStyle="'register-label'"
                      @textInputChange="getFirstName($event)"
                    />
                    <span class="pl-1  measure_unit">{{productDetails.weightUnit}}</span>
                  </b-col>
                  <!-- <b-col class="col-md-6 d-flex">
                   
                  </b-col> -->
                </b-row>
                <!-- <b-row>
                  
                </b-row> -->
                <!-- <div class="row">
                  <b-col class="col-md-4 pt-0 d-flex">
                    <b-col class="pt-0">
                      <span class="sub-title" style="margin-left:42px">Image</span>
                    </b-col>
                    <div class="col-7 mr-2 pt-0">
                      <w-uploader
                        style="display:inline-block"
                        class="mt-1"
                        :dragAndDrop="dragAndDropText"
                        :browse="'Drag file here'"
                        :acceptedMediaType="'Icon'"
                        @rawFiles="getFilesUploaded($event)"
                        :extraDetails="''"
                        :lblClass="'lbl-font-size'"
                        :extraDetailClass="'extra-lbl-font-size'"
                      />
                    </div>
                  </b-col>
                </div>-->
              </div>
            </div>
          </div>
        </div>
        <div class="row m-2 mb-3 bg-white">
          <div class="w-100 m-2">
            <p class="font-weight-bold py-2 pl-3" style="background:#efefef">Channel Presence</p>
          </div>
          <div class="w-100">
            <w-table :tableProperties="tableProperties" :isPadding="true"></w-table>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />

    <b-modal
      id="adjust-seller-stock"
      size="l"
      hide-footer="true"
      centered
      title="Adjust Stock"
    >
      <div class="d-flex w-100 mb-3">
        <span class="button-groups mx-auto">
          <span
            id="increment"
            class="warhouse-btns"
            :class="{ activeTab: isIncrement }"
            @click="isIncrement = true"
          >
            Increment
          </span>
          <span
            id="decrement"
            class="warhouse-btns"
            :class="{ activeTab: !isIncrement }"
            @click="isIncrement = false"
          >
            Decrement
          </span>
        </span>
      </div>
      <div class="mt-3 p-3">
        <div class="row">
          <div class="col border">Quantity</div>
          <!-- <div class="col border border-left-0">05</div> -->
          <b-form-input class="col text-right" v-model="stockQuantity" placeholder="05"></b-form-input>
        </div>
        <div class="row border-left border-right p-3">
          <!-- <div class="col border">Good</div>
          <div class="col border">Bad</div> -->
          <b-form-radio class="label_text mr-3" v-model="goodOrBad" value="good">Good</b-form-radio>
          <b-form-radio class="label_text" v-model="goodOrBad" value="bad">Bad</b-form-radio>
        </div>
        <div class="row">
          <div class="col border">Reason for Adjustment</div>
          <!-- <div class="col border border-left-0">Select</div> -->
          <b-form-select class="col" v-model="reasonSelected" :options="reasonOptions"></b-form-select>
        </div>
      </div>
      <div class="mt-3 d-flex justify-content-around">
        <b-button class="py-1 ml-4" style="min-width:30%" variant="primary" @click="onUpdateAdjustStock">Update</b-button>
        <b-button class="py-1 mr-4" style="min-width:30%" variant="outline-primary" @click="onCancelAdjustStock">Cancel</b-button>
      </div>
      <br>
    </b-modal>
  </div>
  <div v-else>
    <div class="row my-3">
      <b-button
        variant="primary"
        class="ml-auto mr-4 back-button"
        @click="() => { this.showInventoryHistory = false }"
      >Back</b-button>
    </div>
    <!-- TODO: Need to develop UI (table) for Inventory History data -->
    <strong>Inventory History</strong> 
    <div class="mt-2">MSKU - {{this.masterSku}}</div>
    <div class="mt-2">Title - {{this.productDetails.title}}</div>
    <div class="mt-4 container bg-white w-100">
      <w-table bordered head-variant="light" :tableProperties="inventoryHistoryData">
        <template #head(Stock)="data"><span>{{data.label}}</span><img class="info-icon ml-1 mr-auto" src="../../assets/info-icon.png" v-b-popover.hover.top="'Stock before Adjustment'" ></template>
      </w-table>
    </div>
  </div>
</template>

<script>
import ReportsTable from "../../widgets/TableWithCheckbox.vue";
import ProductTable from "../../widgets/ProductTable.vue";
import Modal from "../../widgets/ModalWidget";
import InventoryLedger from "./InventoryLedger.vue";
import InventorySettings from "./InventorySettings.vue";
import ActivityLogs from "./ActivityLogs.vue";
import TextInput from "../../widgets/TextInput.vue";
// import Dropdown from "../../widgets/Dropdown.vue";
// import FileUploader from "../../widgets/FileUploader.vue";
import { getIndividualProduct, inventoryHistory, updateStock } from "../../Service/InventoryService";
import moment from "moment";
export default {
  props: ["masterSku"],
  components: {
    Modal,
    "w-table": ReportsTable,
    "product-table": ProductTable,
    "w-ledger": InventoryLedger,
    "w-setting": InventorySettings,
    "w-logs": ActivityLogs,
    "w-text-input": TextInput
    // "w-dropdown": Dropdown,
    // "w-uploader": FileUploader
  },
  data() {
    return {
      inventoryHistoryData: {
        fields: [
          {
            key: "createdDate",
            label: "Date & Time of Update",
          },
          {
            key: "currentValue",
            label: "Stock",
          },
          {
            key: "addedValue",
            label: "Stock Adjusted",
          },
          {
            key: "reason",
            label: "Reason",
          },
          {
            key: "createdBy",
            label: "User",
          }
        ],
        items: []
      },
      showInventoryHistory: false,
      stockQuantity: null,
      reasonSelected: null,
      reasonOptions: [
        { value: null, text: 'Please select a reason', disabled: true },
        { value: 'Customer Return', text: 'Customer Return' },
        { value: 'Counting Error', text: 'Counting Error' },
        { value: 'Others', text: 'Others' }
      ],
      goodOrBad: 'good',
      isIncrement: true, 
      companyID:sessionStorage.getItem("companyIds"),
      showContent: false,
      tableLoading: false,
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      productDetailslength: null,
      productDetailswidth: null,
      productDetailsheight: null,
      productDetailsweight: null,
      masterID: 0,
      dragAndDropText: "",
      channelName: "Haada",
      Ledger: false,
      Setting: false,
      Logs: false,
      inventoryDetails: {},
      productDetails: {},
      tableProperties: {
        fields: [
          // {
          //   key: "marketplaceId",
          //   label: "Marketplace ID",
          //   thClass: "thClass",
          //   tdClass: "tdClass"
          // },
          {
            key: "channelName",
            label: "Channel",
            thClass: "thClass",
            tdClass: "tdClass"
          },
          {
            key: "channelSku",
            label: "Channel SKU",
            thClass: "thClass",
            tdClass: "tdClass"
          },
          {
            key: "brand",
            label: "Brand",
            thClass: "thClass",
            tdClass: "tdClass"
          },
          {
            key: "title",
            label: "Title",
            thClass: "thClass",
            tdClass: "tdClass"
          },
          {
            key: "alias",
            label: "Alias",
            thClass: "thClass",
            tdClass: "tdClass"
          },
          {
            key: "sellingPrice",
            label: "Selling Price",
            thClass: "thClass",
            tdClass: "tdClass"
          },
          {
            key: "mrp",
            label: "MRP",
            thClass: "thClass",
            tdClass: "tdClass"
          }
        ],
        items: []
      }
    };
  },
  created() {
    this.getPresentChannel();
    this.getInventoryHistory();
  },
  methods: {
    goTStock() {
      this.$emit("backfromproduct", false);
    },
    ledger(event) {
      this.Ledger = true;
    },
    backfromLedger(event) {
      this.Ledger = event;
    },
    setting(event) {
      this.Setting = event;
    },
    backfromSettings(event) {
      this.Setting = event;
    },
    logs(event) {
      this.Logs = event;
    },
    backfromLogs(event) {
      this.Logs = event;
    },
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    getPresentChannel() {
      this.showContent = false;
      this.tableProperties.items = [];
      getIndividualProduct(this.masterSku, this.companyID)
        .then(res => {
          this.tableLoading = false;
          if (!res.errorCode) {
            this.showContent = true;
            let items = [];
            let fields = [
              {
                key: "marketplaceSellableQuantity",
                label: "Marketplace Stock",
                thClass: "thClass",
                tdClass: "tdClass"
              },
              {
                key: "sellerSellableQuantity",
                label: "Seller Stock",
                thClass: "thClass",
                tdClass: "tdClass"
              },
              {
                key: "badQuantity",
                label: "Bad",
                thClass: "thClass",
                tdClass: "tdClass"
              },
              {
                key: "reservedQuantity",
                label: "Reserved",
                thClass: "thClass",
                tdClass: "tdClass"
              },
              {
                key: "lastUpdatedStock",
                label: "Last Updated Stock",
                thClass: "thClass",
                tdClass: "tdClass"
              },
              {
                key: "user",
                label: "User",
                thClass: "thClass",
                tdClass: "tdClass"
              },
              {
                key: "history",
                label: "",
                thClass: "thClass",
                tdClass: "tdClass"
              }
            ];
            items.push(res.response.productInventoryDto);
            items[0]['history'] = "Inventory History"; 
            items[0]['lastUpdatedStock'] = items[0]['lastUpdatedValue'];
            delete items[0]['lastUpdatedValue'];
            items[0]['user'] = items[0]['by'];
            delete items[0]['by'];
            this.inventoryDetails['fields'] = fields;
            this.inventoryDetails['items'] = items;            
            this.tableProperties.items =
              res.response.channelPresenceDto;
            this.productDetails =
              res.response.productDetailsDto;
            this.productDetailslength =
              res.response.productDetailsDto.length;
            this.productDetailswidth =
              res.response.productDetailsDto.width;
            this.productDetailsheight =
              res.response.productDetailsDto.height;
            this.productDetailsweight =
              res.response.productDetailsDto.weight;
          } else {
            this.showContent = false;
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch(err => {
          this.showContent = false;
          this.tableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    getInventoryHistory() {
      inventoryHistory(this.masterSku, 0, 10).then(res=> {
        this.inventoryHistoryData.items = [];
        this.inventoryHistoryData.items = res.content.map(item=> {
          return {
            ...item,
            createdDate : moment(item.createdDate,'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY, h:mm a')
          }
        });
      }).catch(err=> console.log(err));
    },
    onSellerStockCLick(e) {
      this.$bvModal.show("adjust-seller-stock");
      console.log(e);
    } ,
    goToStockHistory(e) {
      // TODO: Show inventory history
      this.showInventoryHistory = true;
      console.log(e);
    },
    onUpdateAdjustStock() {
      let payload = {
        masterSku: this.masterSku,
        stockQuantity: this.stockQuantity,
        reasonForAdjustment: this.reasonSelected
      }
      updateStock(this.isIncrement, this.goodOrBad === 'bad' ? true : false, payload).then(res=> {
        console.log(res);
        this.getPresentChannel();
        this.$bvModal.hide("adjust-seller-stock");
      }).catch(err=>console.log(err)); 
      console.log(payload)
    },
    onCancelAdjustStock() {
      this.$bvModal.hide("adjust-seller-stock");
    }
  }
};
</script>
<style scoped>
.form-control, .custom-select {
  height: auto !important;
  border-radius: 0 !important;
  border-left: 0 !important;
}
.form-control::-webkit-input-placeholder { 
  text-align:right; 
}
</style>
<style> 
.channel_presence > .card-body {
  padding: 0px !important;
}
.channel_detail > .card-body {
  padding: 0px !important;
}
.product_sku .card-body {
  padding: 0;
}
.modal-body {
  padding: 0 2rem !important;
}
</style>
<style scoped>
.channel_presence ::v-deep table thead tr th{
  text-align: center !important;
}
.channel_presence ::v-deep table tbody tr td{
  text-align: center !important;
}
.channel_presence ::v-deep table thead tr th:first-child{
  text-align: left !important;
}
.channel_presence ::v-deep table tbody tr td:first-child{
  text-align: left !important;
}

.back-button {
  width: 149px;
  height: 33px;
  border-radius: 2px;
  border: solid 1px #5fa6e7;
  background-color: #5fa6e7;
  line-height: normal;
}
.save_back_btn {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  background: #5fa6e7;
  border-radius: 2px;
  border: solid 1px #5fa6e7;
  padding: 3px 60px;
}
.channel_presence .channel_label,
.channel_detail .channel_label {
  color: #1b1c1d;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 16px;
  font-weight: 600;
}
.channel_detail .detail_label {
  color: #1b1c1d;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
}
.measurement {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  color: #1b1c1d;
}
.measure_unit {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  color: #1b1c1d;
}
#inventory-table .table-div {
  border: none;
}
.sub-div {
  width: 100%;
}
.img-div {
  width: 200px;
  padding: 5px;
}
.table-div {
  display: flex;
  background-color: #ffffff;
  border-radius: 5px;
}
.add {
  width: 20.1px;
  height: 20.1px;
  object-fit: contain;
}
.sub-title {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #1b1c1d;
  margin-bottom: 0px;
}
::v-deep .upload-border {
  width: 120px;
  height: 33px !important;
  border: none;
  outline-style: dashed;
  outline-color: #8c8c8d;
  outline-width: 1.5px;
  background-color: #ffffff;
}
::v-deep .drag-drop-details {
  color: #1b1c1d;
  opacity: 0.5;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  vertical-align: -webkit-baseline-middle;
}
::v-deep .drag-drop {
  margin-top: 0 !important;
  margin-left: -65px !important;
}
.activeTab {
  background: #2185d0;
  color: #fff !important;
  border-radius: 20px;
}
.button-groups {
  border: 0.8px solid #2185d0;
  border-radius: 26px;
  min-height: 35px;
  padding: 0;
  display: inline-block;
}
.warhouse-btns {
  padding: 8px 35px;
  cursor: pointer;  
  transition: all 0.15s ease-in-out;
}
.button-groups span {
  font-size: 13px;
  font-weight: 600;
  color: #2185d0;
  border: none;
  display: inline-block;
}
.info-icon {
  height:16px;
  margin-bottom:2px;
  cursor: default;
}
</style>
