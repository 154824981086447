import { render, staticRenderFns } from "./RemittedStatus.vue?vue&type=template&id=34c1c91f&scoped=true&"
import script from "./RemittedStatus.vue?vue&type=script&lang=js&"
export * from "./RemittedStatus.vue?vue&type=script&lang=js&"
import style0 from "./RemittedStatus.vue?vue&type=style&index=0&id=34c1c91f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34c1c91f",
  null
  
)

export default component.exports