<template>
  <div class="">
    <b-table
      thead-class="theadClass"
      id="my-table"
      :items="tableData.items"
      :class="labelClass"
      :fields="tableData.fields"
      responsive
      empty-text="No Records Found"
      show-empty
      bordered
    >
      <!-- for generated reports table button -->
      <template v-slot:cell(action_field)="data">
        <div class="d-flex" style="margin:2px">
          <b-button class="download " @click="downloadEvent(data.item)"
            >Download</b-button
          >
        </div>
      </template>
      <!-- for create new reports table button -->
      <template v-slot:cell(action_generate)="data">
        <div class="d-flex" style="margin:2px">
          <b-button class="download " @click="downloadEvent(data.item)"
            >Generate</b-button
          >
        </div>
      </template>
      <template v-slot:cell(file_field)="data">
        <div class="d-flex" style="margin:2px">
          <button class="downloadd" @click="downloadCSEvent(data.item)">
            Download <img src="../assets/download.svg" class="pl-2" />
          </button>
        </div>
      </template>
      <!-- for seller page -->
      <template v-slot:cell(seller_field)="{ item }">
        <div class="d-flex" style="margin:2px;">
          <span style="min-width:70px" v-for="stock in item.stockes" :key="stock.index">
            <span style="" >{{ stock}}</span>
          </span>
        </div>
      </template>
      <template #head(unMapped)="data">
        <span>{{data.label}}<img class="info-icon ml-2 mr-auto" src="../assets/info-icon.png" v-b-popover.hover.right="tooltips.unmappedListings"></span>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: ["tableData"],
  data() {
    return {
      tooltips: {
        unmappedListings : 'The listings which are not mapped on to the system. Need to map the products to avail Centralized inventory functionality.',
      }
    }
  },
  methods: {
    generateDataEvent(data) {
      this.$emit("generateDataEvent", data);
    },
    downloadEvent(data) {
      this.$emit("downloadEvent", data);
    },
    downloadCSEvent(data) {
      this.$emit("downloadCSVEvent", data);
    },
    image(data) {
      this.$emit("imageeEvent", data);
    },
  },
};
</script>
<style scoped>
.info-icon {
  height:18px;
  margin-bottom:2px;
  cursor: default;
}
.generate {
  width: 84px;
  height: 24px;
  border-radius: 2px;
  border: solid 1px #2e8cd3;
  background-color: #ffffff;
}
.downloadd {
  color: #2e8cd3;
  border-radius: 2px;
  border: solid 1px #2e8cd3;
  background-color: #ffffff;
  padding: 3px 20px;
}
::v-deep .theadClass {
  background-color: #EFEFEF !important;
}
::v-deep .theadClass tr th {
  color: #1B1C1D !important;
  font-size: 14px !important;
  font-family: "roboto-bold" !important;
}
::v-deep .table td {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  text-align: left;
  color: #1B1C1D !important;
}
/* for responsive */
@media screen and (max-width: 768px){
  ::v-deep .theadClass tr th {
    font-size: 12px !important;
  }
  .seller_table .table tbody td{
    font-size: 11px;
  }
}

</style>

