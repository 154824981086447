<template>
  <div class="remittance-dashboard">
    <b-card
      class="m-2 remittance-filter"
      v-if="isDesktopFilterOpen"
    >
      <div class="row">
        <div class="col-md-6 col-12 py-0">
          <label class="tree-label">Purchase Date </label>
          <date-picker
            v-model="selectedDate"
            :lang="'en'"
            range
            confirm
            :shortcuts="[]"
            @clear="clearPurchaseDate($event)"
            @confirm="selectDateRange($event)"
          ></date-picker>
        </div>
        <!-- <div class="col-md-3 py-0">
          <w-tree-dropdown
            :labelText="'Channels'"
            :rootOptions="channelListOptions"
            :selectedValue="selectedChannelValues"
            @selectedField="onSelectedChannels($event)"
          />
        </div> -->
        <img
          class="mt-3 close-filter"
          src="../../assets/CloseModel.svg"
          width="15px"
          style="cursor:pointer"
          @click="closeDesktopFilter"
        />
      </div>
    </b-card>
    <!-- filter-button -->
    <div class="col-md-2 mt-4 py-0 pl-2" @click="openDesktop_filter">
      <img src="../../assets/SVG/desktopFilter.svg" style="cursor:pointer" />
      <span style="color:#2E8CD3; font-size:14px; cursor:pointer">Filters</span>
    </div>

    

    <!-- <div class="mr-3 d-flex justify-content-end">
      <b-pagination
        class="mb-0"
        hide-goto-end-buttons
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        :prev-text="'⏴'"
        :next-text="'⏵'"
        @input="paginateDetails(currentPage)"
      ></b-pagination>
    </div> -->
    <section v-if="loadingWidget">
      <div class="loader-div">
        <div class="loader"></div>
        <span class="text-danger my-auto ml-2" style="font-size:13px">
          <strong> Loading... </strong>
        </span>
      </div>
    </section>
    <section class="mt-3" v-else>
      <!-- channel tabs -->
      <div class="mx-3 mb-2 d-flex border-bottom channel-list">
        <div
          class="mr-5"
          v-for="(channel, index) in displayRemittance"
          :key="index"
        >
          <p
            class="mb-0"
            :class="isActiveTab == index ? 'active' : 'inactive'"
            @click="clickOnActive(index)"
          >
            {{ channel.channelName }}
          </p>
        </div>
      </div>
      <!-- remittance graphs with cards -->
      <div v-if="displayRemittance.length > 0">
        <div
          v-for="(data, index) in displayRemittance"
          :key="index"
          :class="{ activeGraphData: isActiveTab == index }"
          class="d-none"
        >
        
          <div class="card mx-2 mt-4 p-3 remittance-card-wrapper">
            <div class="my-2 dashbord-card justify-content-around flex-wrap">
              <div>
                <div class="p-2 remitence-card" style="border-left:4px solid #1D408E;">
                  <p class="remitence-card-title mb-0">
                    Total Number of Orders
                  </p>
                  <div
                    class=" mb-0"
                    style=" font-size:20px; font-weight:600; color:#1B1C1D"
                  >
                    <p class="mb-0 py-2">
                      {{ data.remittanceDashboardDto.totalOrders }}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div class="p-2 remitence-card" style="border-left:4px solid #F43B3B;">
                  <p class="remitence-card-title mb-0">Remitted Orders</p>
                  <div
                    class=" mb-0"
                    style=" font-size:20px; font-weight:600;color:#1B1C1D"
                  >
                    <p class="mb-0 py-2">
                      {{ data.remittanceDashboardDto.totalRemittedOrders }}
                    </p>
                  </div>
                  <p
                    class="total-usage d-flex justify-content-between mt-auto m-0"
                  >
                    <span class="percentage-label">Amount</span>
                    <span >
                      {{ data.remittanceDashboardDto.currency }}
                      {{ data.remittanceDashboardDto.totalRemittedAmount }}
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <div class="p-2 remitence-card" style="border-left:4px solid #EDBD1B;">
                  <p class="remitence-card-title mb-0">Unremitted Orders</p>
                  <div
                    class=" mb-0"
                    style=" font-size:20px; font-weight:600; color:#1B1C1D"
                  >
                    <p class="mb-0 py-2">
                      {{ data.remittanceDashboardDto.totalUnremittedOrders }}
                    </p>
                  </div>
                  <p
                    class="total-usage mb-0 mt-auto d-flex justify-content-between"
                  >
                    <span class="percentage-label">Unremitted %</span>
                    <span >
                      {{
                        data.remittanceDashboardDto.unremittedOrderPercentage
                      }}
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <div class="p-2 remitence-card" style="border-left:4px solid #741BED;">
                  <p class="remitence-card-title mb-0">Zero Remitted Orders</p>
                  <div
                    class=" mb-0"
                    style=" font-size:20px; font-weight:600; color:#1B1C1D"
                  >
                    <p class="mb-0 py-2">
                      {{ data.remittanceDashboardDto.totalZeroRemittedOrders }}
                    </p>
                  </div>
                  <p
                    class="total-usage mb-0 mt-auto d-flex justify-content-between"
                  >
                    <span class="percentage-label">Zero Remitted %</span>
                    <span>
                      {{ data.remittanceDashboardDto.zeroOrderPercentage }}
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <div class="p-2 remitence-card" style="border-left:4px solid #60C7FD;">
                  <p class="remitence-card-title mb-0">
                    Negative Remitted Orders
                  </p>
                  <div
                    class=" mb-0"
                    style=" font-size:20px; font-weight:600; color:#1B1C1D"
                  >
                    <p class="mb-0 py-2">
                      {{
                        data.remittanceDashboardDto.totalNegativeRemittedOrders
                      }}
                    </p>
                  </div>
                  <p
                    class="total-usage mb-0 mt-auto d-flex justify-content-between"
                  >
                    <span class="percentage-label">Negative Remitted %</span>
                    <span>
                      {{ data.remittanceDashboardDto.negativeOrderPercentage }}
                    </span>
                  </p>
                </div>
              </div> 
            </div>
            <div class="row border-top mt-3">
              <div class="col-lg-3 col-xl-3 col-md-6 col-12">
                <w-dropdown
                  class="ml-3 mt-3"
                  :label-text="''"
                  :selectedValue="defaultValue"
                  :selectOption="showRemittanceOptions"
                  @selectedOption="onChangeRemittanceFilter($event)"
                />
              </div>
            </div>
            <!-- order-wise button graph -->
            <div class="row p-0 m-0" v-if="isOrderWiseGraph">
              <div class="col-11 p-0">
                <GChart
                  v-if="isOrderWiseGraph && isActiveTab == index"
                  class="p-0 m-0 mx-auto"
                  type="ColumnChart"
                  :data="data.graphOrder"
                  :options="orderChartOptions"
                  :resizeDebounce="500"
                />
              </div>
            </div>
            <!-- revenue-wise button graph -->
            <div class="row p-0 m-0" v-if="isRevenueWiseGraph">
              <div class="col-11 p-0">
                <GChart
                  v-if="isRevenueWiseGraph && isActiveTab == index"
                  class="p-0 m-0 mx-auto"
                  type="ColumnChart"
                  :data="data.graphRevenue"
                  :options="revenueChartOptions"
                  :resizeDebounce="500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
    <!-- <w-loading-spinner :loadSpinner="loadingWidget" :isSchedulePickup="true" /> -->
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import Modal from "../../widgets/ModalWidget";
import TreeDropdown from "../../widgets/TreeDropdown";
import { GChart } from "vue-google-charts";
// import LoadingSpinner from "../../widgets/LoadingSpinner";
import {
  getRemittanceCardData,
  getChannelList,
} from "../../Service/RemittenceService";
// import StackedGraph from "../../widgets/StackedGraph";
import Dropdown from "../../widgets/Dropdown.vue";
export default {
  components: {
    Modal,
    DatePicker,
    // StackedGraph,
    GChart,
    "w-tree-dropdown": TreeDropdown,
    // "w-loading-spinner": LoadingSpinner,
    "w-dropdown": Dropdown,
  },
  props:["isGetData"],
  data() {
    return {
      isActiveTab: 0,
      isDesktopFilterOpen: false,
      companyID: sessionStorage.getItem("companyIds"),
      defaultValue: null,
      showRemittanceOptions: [
        {
          value: null,
          text: "Order Wise",
        },
        {
          value: "revenue",
          text: "Revenue Wise",
        },
      ],
      selectedSortBy: "Order-Wise",
      orderStatus: "order",

      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      isOrderWiseGraph: true,
      isRevenueWiseGraph: false,
      loadingWidget: false,

      // currentPage: 1,
      // perPage: 2,
      // rows: 0,
      displayRemittance: [],
      currencySymbol: "",

      selectedDate: "",
      startDate: "",
      endDate: "",
      remittenceCardData: [],

      channelListOptions: [],
      selectedChannelValues: [],

      // for graph order wise graph
      orderChartOptions: {
        chartArea: {
          left: "10%",
          top:"8%",
          width: "100%",
          // height:"70%"
        },
        isStacked: true,
        hAxis: {
          format: "MMM-dd-yyyy",
          title: "Order Date",
          titleTextStyle: {
            italic: false,
            fontSize: 12,
          },
          textStyle: {
            fontSize: 10,
          },
          gridlines: {
            color: "transparent",
          },
        },
        vAxis: {
          title: "Order Count",
          titleTextStyle: {
            italic: false,
            fontSize: 12,
          },
          textStyle: {
            fontSize: 10,
          },
          gridlines: {
            color: "transparent",
          },
        },
        legend: {
          position: "bottom",
          textStyle: { fontSize: 12 },
        },
        tooltip: {
          textStyle: {
            fontSize: 12,
          },
        },
        // bar: { groupWidth: "40%" },
        colors: ["#9affc8", "#ff9a9e", "#9a9eff", "#791e94"],
        height: 450,
        series: {
          targetAxisIndex: 0,
          type: "bars",
        },
        animation: {
          duration: 300,
          startup: true
        }
      },
      // for revenue wise graph
      revenueChartOptions: {
        chartArea: {
          left: "10%",
          top:"8%",
          width: "100%",
          // height:"40%"
        },
        isStacked: true,
        hAxis: {
          format: "MMM-dd-yyyy",
          title: "Order Date",
          titleTextStyle: {
            italic: false,
            fontSize: 12,
          },
          textStyle: {
            fontSize: 10,
          },
          gridlines: {
            color: "transparent",
          },
        },
        vAxis: {
          title: "",
          titleTextStyle: {
            italic: false,
            fontSize: 12,
          },
          textStyle: {
            fontSize: 10,
          },
          gridlines: {
            color: "transparent",
          },
        },

        legend: {
          position: "bottom",
          textStyle: { fontSize: 12 },
        },
        tooltip: {
          textStyle: {
            fontSize: 12,
          },
        },
        // bar: { groupWidth: "40%" },
        colors: ["#9a9eff"],
        height: 450,
        series: {
          targetAxisIndex: 0,
          type: "bars",
        },
        animation: {
          duration: 300,
          startup: true
        }
      },
    };
  },
  created() {
    if(this.isGetData) this.getData();
  },
  methods: {
    getData(){
      let first = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
      first = new Date(first.setHours(0, 0, 0, 0));
      let last = new Date();
      last = new Date(last.setHours(0, 0, 0, 0));

      this.selectedDate = [first, last];
      //
      this.getRemittanceCardData();
      this.getChannelList();
    },


    clickOnActive(tab) {
      this.isActiveTab = tab;
    },

    onChangeRemittanceFilter(event) {
      this.selectedSortBy = event;
      this.defaultValue = event;
      if (this.selectedSortBy == null) {
        this.isOrderWiseGraph = true;
        this.isRevenueWiseGraph = false;
      } else {
        this.isOrderWiseGraph = false;
        this.isRevenueWiseGraph = true;
      }
    },

    // for pagination method
    // paginateDetails(currentPage) {
    //   const start = (currentPage - 1) * this.perPage;
    //   this.displayRemittance = this.remittenceCardData.slice(start, start + 2);

    //   this.isOrderWiseGraph = true;
    //   this.isRevenueWiseGraph = false;
    // },

    // Api for remittance dashboard card and graph
    getRemittanceCardData() {
      this.loadingWidget = true;
      getRemittanceCardData(
        this.startDate,
        this.endDate,
        this.selectedChannelValues,
        this.companyID
      )
        .then((res) => {
          this.loadingWidget = false;
           if (res.response.content.length > 0) {
            let resp = res.response.content ? res.response.content : [] ;
            this.remittenceCardData = resp;
            // this.displayRemittance = this.remittenceCardData.slice(0, 2);
            this.displayRemittance = this.remittenceCardData;
            // this.rows = res.response.totalElements;

            this.currencySymbol = this.remittenceCardData[0].remittanceDashboardDto.currency;
            this.revenueChartOptions.vAxis.title = `Revenue (${this.currencySymbol})`;
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage || "No record found";
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // for channel list
    getChannelList() {
      getChannelList(this.companyID)
        .then((res) => {
          res.forEach((element) => {
            this.channelListOptions.push({
              id: element.channelId,
              label: element.channelName,
            });
            // this.selectedChannelValues.push(element.channelId);
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    selectDateRange(event) {
      this.startDate = moment(this.selectedDate[0]).valueOf();
      this.endDate = moment(this.selectedDate[1]).valueOf();
      this.getRemittanceCardData();
    },
    clearPurchaseDate() {
      this.startDate = "";
      this.endDate = "";
      this.getRemittanceCardData();
    },

    onSelectedChannels(event) {
      this.selectedChannelValues = event;
      this.getRemittanceCardData();
    },
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    // for open a desktop filter
    openDesktop_filter() {
      this.isDesktopFilterOpen = !this.isDesktopFilterOpen;
    },
    closeDesktopFilter() {
      this.isDesktopFilterOpen = false;
    },
  },
};
</script>
<style scoped>
/* .remittance-dashboard .order-btn {
  display: flex;
  justify-content: flex-end;
}
.remittance-dashboard .order-btn ::v-deep .tabs .nav-pills {
  background: #fff;
}
.remittance-dashboard .order-btn ::v-deep .tabs .nav-pills .nav-item {
  font-size: 14px;
}
.remittance-dashboard .order-btn ::v-deep .nav-pills .nav-link {
  color: #5fa6e7;
  border: 1px solid #5fa6e7;
  border-radius: unset;
  padding: 4px 14px;
}
.remittance-dashboard .order-btn ::v-deep .nav-pills .nav-link.active {
  background: #5fa6e7;
  color: #fff;
  border-radius: unset;
} */
/* card  */
.remitence-card {
  min-width: 180px;
  min-height: 100px;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(64 64 64 / 16%);
  display: flex;
  flex-direction: column;
  /* height: 6rem; */
  /* border-radius: 3px; */
}
.remitence-card-title {
  color: #1b1c1d;
  font-size: 12px;
  /* font-weight: 700; */
  font-family: "proxima-nova", sans-serif !important;
  /* letter-spacing: 0.5px; */
}
.percentage-label{
  color: #1B1C1D;
  font-size: 12px;
  letter-spacing: 0px;
  font-weight: normal;
}
/* .remitted-style {
  font-size: 14px;
  font-weight: 700;
} */
/* for loader */
.loader-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}
/* .loader-section{
  
} */
.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #d9534f;
  border-left: 3px solid #d9534f;
  border-right: 3px solid #d9534f;
  width: 32px;
  height: 32px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.close-filter {
  position: absolute;
  top: 0px;
  right: 20px;
}
.remittance-filter {
  /* width: 93%; */
  height: auto;
}
.total-usage {
  font-size: 14px;
  font-weight: 700;
  color: #1b1c1d;
}
.active {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  color: #2e8cd3;
  cursor: pointer;
  padding-bottom: 5px;
  border-bottom: 3px solid #2e8cd3;
}
.inactive {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  color: #666666;
  cursor: pointer;
}
.activeGraphData {
  display: block !important;
}
.channel-list{
  white-space: nowrap;
  overflow-x: auto;
}
.dashbord-card{
  display: flex;
  overflow-x: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.dashbord-card::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dashbord-card {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
@media screen and (max-width:1300px) {
  .dashbord-card{
    display: flex;
    overflow-x:auto;
  }
}
@media screen and (max-width:768px) {
  .remittance-filter ::v-deep .mx-range-wrapper {
    width: 100%;
  }
  .remittance-filter ::v-deep .mx-calendar {
    width: 100%;
  }
  .remittance-filter ::v-deep .mx-calendar-content {
    width: 100%;
  }
  .remittance-filter ::v-deep .mx-datepicker-popup {
    left: 0px !important;
  }
}
</style>
