<template>
  <div class="mt-2">
    <div v-if="!isOrderDetailsTab">
      <b-card class="m-2 mt-4 remittance-filter" :body-class="'card-padding'" v-if="isDesktopFilterOpen">
        <div class="row">
          <div class="col-md-3 py-0">
            <label class="tree-label">
              Purchase Date
              <!-- <span v-if="startDate === ''">: Last 30days</span> -->
            </label>
            <date-picker
              v-model="selectedDate"
              :lang="'en'"
              range
              confirm
              :shortcuts="[]"
              @clear="clearPurchaseDate($event)"
              @confirm="selectDateRange($event)"
            ></date-picker>
          </div>
          <div class="col-md-3 py-0">
            <w-tree-dropdown
              :labelText="'Remittence'"
              :rootOptions="problemReasonListOptions"
              :selectedValue="problemReasonListValues"
              @selectedField="selectedProblemReasonCategory($event)"
            />
          </div>
          <div class="col-md-3 py-0">
            <w-tree-dropdown
              :labelText="'Channels'"
              :rootOptions="channelListOptions"
              :selectedValue="selectedChannelValues"
              @selectedField="onSelectedChannels($event)"
            />
          </div>
          <div class="col-md-3 py-0 order-dropdown">
            <label class="tree-label">Order ID/Channel Order ID</label>
            <w-text-input
              :fieldType="'text'"
              :disabled="false"
              :defaultValue="channelOrderID"
              :labelStyle="'register-label'"
              @textInputChange="getchannelOrderID($event)"
            />
          </div>
        </div>
         <img class="mt-3 close-filter" src="../../assets/CloseModel.svg" width="15px" style="cursor:pointer" @click="closeDesktopFilter">
      </b-card>
      <!-- <w-table :tableProperties="tableProperties" /> -->
      <div class="order-remittance">
        <w-table
        class="mt-5"
          :tableProperties="tableProperties"
          :tableLoading="tableLoading"
          @getOrderDetailsPage="goToOrderDetailsPage($event)"
          @getSelectedPageEvent="getSelectedPage($event)"
          @getSelectedPerPageEvent="getSelectedPerPage($event)"
          :isRemittanceStatue="true"
          :isFilterButton="true"
          @openDesktop_filterEvent="openDesktop_filter"
        />
      </div>
    </div>

    <div class="order-detail-page" v-else>
      <w-Order-Details-Table
        :orderIdParams="orderDetialsParams.orderId"
        :channelIdParams="orderDetialsParams.channelId"
        :companyIdParams="orderDetialsParams.companyId"
        @gotoBackPage="goBackToRemittanceIndividualPage()"
      />
    </div>

    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import Modal from "../../widgets/ModalWidget";
import TextInput from "../../widgets/TextInput.vue";
import TreeDropdown from "../../widgets/TreeDropdown";
import RemittenceCollapseTable from "../../widgets/RemittenceCollapseTable.vue";
import OrderDetailsTable from "../../widgets/OrderDetailsTables";
import {
  getRemittedStatusOrder,
  getRemittanceProblem,
} from "../../Service/RemittenceService";
export default {
  components: {
    Modal,
    DatePicker,
    "w-text-input": TextInput,
    "w-tree-dropdown": TreeDropdown,
    "w-table": RemittenceCollapseTable,
    "w-Order-Details-Table": OrderDetailsTable,
  },
  props: ["channelListOptions", "isGetData"],
  data() {
    return {
      isDesktopFilterOpen:false,
      companyID:sessionStorage.getItem("companyIds"),
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      tableLoading: false,

      isOrderDetailsTab: false,
      orderDetialsParams: null,

      selectedDate: "",
      startDate: "",
      endDate: "",

      problemReasonListValues: [],
      problemReasonListOptions: [],

      selectedChannelValues: [],
      channelOrderID: "",

      tableProperties: {
        fields: [
          {
            key: "orderId",
            label: "Order ID",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "channelOrderId",
            label: "Channel Order ID",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "purchaseDate",
            label: "Purchase Date",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "product",
            label: "Product",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "status",
            label: "Status",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "orderAmount",
            label: "Order Amount",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "remittedAmount",
            label: "Remitted Amount",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "remittedPercentage",
            label: "Remittance %",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "remittedOn",
            label: "Remitted On",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "remitence_Calculation",
            label: "",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
        ],
        items: [],
        perPage: 50,
        currentPage: 1,
        totalRows: 0,
      },
    };
  },

  created() {
    // // default last 30 days

    // let first = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    // first = new Date(first.setHours(0, 0, 0, 0));
    // let last = new Date();
    // last = new Date(last.setHours(0, 0, 0, 0));
    // this.selectedDate = [first, last];
    // //
    // this.getRemittedStatusOrder();
    // this.getRemittanceProblem();
    if(this.isGetData){
      this.getData();
    }
  },

  methods: {
    getData(){
          // default last 30 days
      let first = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
      first = new Date(first.setHours(0, 0, 0, 0));
      let last = new Date();
      last = new Date(last.setHours(0, 0, 0, 0));
      this.selectedDate = [first, last];
      this.getRemittedStatusOrder();
      this.getRemittanceProblem();
    },

    getRemittedStatusOrder() {
      this.tableLoading = true;
      getRemittedStatusOrder(
        this.tableProperties.currentPage - 1,
        this.tableProperties.perPage,
        this.startDate,
        this.endDate,
        this.problemReasonListValues,
        this.selectedChannelValues,
        this.channelOrderID,
        this.companyID,
      )
        .then((res) => {
          this.tableLoading = false;
          if (!res.errorCode) {
            let resp = (res.response && res.response.content) || [];
            this.tableProperties.items = resp;
            this.tableProperties.totalRows =
              res.response && res.response.totalElements;
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getRemittanceProblem() {
      getRemittanceProblem()
        .then((res) => {
          console.log("remittance problem", res.response);
          let resp = res.response;
          resp.forEach((el) => {
            this.problemReasonListOptions.push({
              label: el.text,
              id: el.remittanceProblem,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    selectDateRange(event) {
      this.startDate = moment(this.selectedDate[0]).valueOf();
      this.endDate = moment(this.selectedDate[1]).valueOf();
      this.getRemittedStatusOrder();
    },
    clearPurchaseDate() {
      this.startDate = "";
      this.endDate = "";
      this.getRemittedStatusOrder();
    },

    selectedProblemReasonCategory(event) {
      this.problemReasonListValues = event;
      this.getRemittedStatusOrder();
    },
    onSelectedChannels(event) {
      this.selectedChannelValues = event;
      this.getRemittedStatusOrder();
    },
    getchannelOrderID(event) {
      this.channelOrderID = event;
      this.tableProperties.currentPage = 1;
      this.getRemittedStatusOrder();
    },

    goToOrderDetailsPage(orderDetails) {
      this.orderDetialsParams = orderDetails;
      this.isOrderDetailsTab = true;
    },
    goBackToRemittanceIndividualPage() {
      this.isOrderDetailsTab = false;
    },

    getSelectedPage(currentPage) {
      this.tableProperties.currentPage = currentPage;
      this.getRemittedStatusOrder();
    },

    getSelectedPerPage(perPage) {
      this.tableProperties.perPage = perPage;
      this.tableProperties.currentPage = 1;
      this.getRemittedStatusOrder();
    },

    // modal close
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
     // for open a desktop filter
    openDesktop_filter(){
      this.isDesktopFilterOpen = !this.isDesktopFilterOpen;
    },
    closeDesktopFilter(){
      this.isDesktopFilterOpen=false;
    }
  },
};
</script>

<style scoped>
.show-class {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  line-height: 2;
  text-align: left;
  color: #767676;
}
.order-dropdown ::v-deep .form-control {
  height: 38px !important;
}
/* .order-remittance ::v-deep .order-wise{
  justify-content: flex-end !important;
} */
.order-remittance ::v-deep table thead tr th {
  text-align: center !important;
}
.order-remittance ::v-deep table tbody tr td {
  text-align: center !important;
}
.order-remittance ::v-deep table thead tr th:first-child {
  text-align: left !important;
}
.order-remittance ::v-deep table tbody tr td:first-child {
  text-align: left !important;
}
.order-detail-page ::v-deep .whithout-tab-content{
    margin: 0;
  }
.close-filter{
  position: absolute;
  top: 0px;
  right: 20px;
}

@media screen and (max-width:768px) {
.remittance-filter ::v-deep .mx-range-wrapper {
    width: 100%;
  }
.remittance-filter::v-deep .mx-calendar {
    width: 100%;
  }
.remittance-filter::v-deep .mx-calendar-content {
    width: 100%;
  }
.remittance-filter::v-deep .mx-datepicker-popup {
    left: 0 !important;
    top: 0 !important;
  }
}

@media screen and (max-width:340px) {
  .order-remittance ::v-deep .remitteance-pagination{
    display: flex !important;
  }
  .order-remittance ::v-deep .remitteance-pagination .page-link{
    min-width: 20px !important;
  }
  .order-remittance ::v-deep .remitteance-pagination .show-class {
    margin-left: 7px !important;
  }
}

</style>
