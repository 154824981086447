<template>
  <div class="sku-remitance mt-2">
    <b-card class="m-2 remittance-filter" :body-class="'card-padding'" v-if="isDesktopFilterOpen">
      <div class="row filters">
        <div class="col-md-3 ">
          <label class="tree-label">
            Date Range
          </label>
          <div>
            <date-picker
              v-model="rangepicaker"
              type="date"
              lang="en"
              confirm
              range
              :shortcuts="dateShortcuts"
              @clear="clearDateRange($event)"
              @confirm="getSelectedDateRange($event)"
            ></date-picker>
          </div>
        </div>
        <div class="col-md-3  performance">
          <label class="tree-label">Sort By</label>
          <w-dropdown
            :label-text="''"
            :selectedValue="null"
            :selectOption="sortByListOptions"
            @selectedOption="selectedSortByCategory($event)"
          />
        </div>
        <div class="col-md-3">
          <w-tree-dropdown
            :labelText="'Brands'"
            :rootOptions="brandsListOptions"
            :selectedValue="brandsListValues"
            @selectedField="selectedBrandsCategory($event)"
          />
        </div>
        <div class="col-md-3">
          <w-tree-dropdown
            :labelText="'Channels'"
            :rootOptions="channelListOptions"
            :selectedValue="channelListValues"
            @selectedField="selectedChannelsCategory($event)"
          />
        </div>
      </div>
        <img class="mt-3 close-filter" src="../../assets/CloseModel.svg" width="15px" style="cursor:pointer" @click="closeDesktopFilter">
    </b-card>
    <w-table
      class="mt-3"
      :tableLoading="tableLoading"
      :tableProperties="tableProperties"
      :showRemittenceStatusSearchFilter="true"
      @getSelectedPageEvent="getSelectedPage($event)"
      @getSelectedPerPageEvent="getSelectedPerPage($event)"
      @downloadSkuWiseEvent="downloadSkuWise"
      :isFilterButton="true"
      @openDesktop_filterEvent="openDesktop_filter"
    />
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />

    <w-loading-spinner :loadSpinner="loadingWidget" />
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import Modal from "../../widgets/ModalWidget";
import TreeDropdown from "../../widgets/TreeDropdown";
import Dropdown from "../../widgets/Dropdown.vue";
import RemittenceCollapseTable from "../../widgets/RemittenceCollapseTable.vue";
import LoadingSpinner from "../../widgets/LoadingSpinner";
import {
  getBrandsListing,
  getPerformanceList,
  getSkuWiseListWithFilter,
  downloadSkuWiseRemittance,
} from "../../Service/RemittenceService";

export default {
  components: {
    Modal,
    DatePicker,
    "w-dropdown": Dropdown,
    "w-tree-dropdown": TreeDropdown,
    "w-table": RemittenceCollapseTable,
    "w-loading-spinner": LoadingSpinner,
  },

  props: ["channelListOptions", "isGetData"],

  data() {
    return {
      isDesktopFilterOpen:false,
      companyID:sessionStorage.getItem("companyIds"),
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      tableLoading: false,
      loadingWidget: false,

      selectedDateType: "",
      rangepicaker: "",
      startDate: "",
      endDate: "",
      dateShortcuts: [
        {
          text: "Today",
          onClick: () => {
            let startOfDay = new Date();
            startOfDay.setHours(0, 0, 0, 0);
            let endofDay = new Date();
            endofDay.setHours(23, 59, 59, 999);

            this.rangepicaker = [startOfDay, endofDay];
            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            this.getSkuWiseListWithFilter();
          },
        },
        {
          text: "Last 7 Days",
          onClick: () => {
            let first = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
            first = new Date(first.setHours(0, 0, 0, 0));
            let last = new Date();
            last = new Date(last.setHours(0, 0, 0, 0));

            this.rangepicaker = [first, last];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            this.getSkuWiseListWithFilter();
          },
        },
        {
          text: "Last Week",
          onClick: () => {
            let d1 = new Date(); // get current date
            let first = d1.getDate() - d1.getDay(); // First day is the day of the month - the day of the week
            let last = first - 6; // last day is the first day + 6

            let firstday = new Date(d1.setDate(first));
            firstday = new Date(firstday.setHours(23, 59, 59, 999));

            let d2 = new Date();
            let lastday = new Date(d2.setDate(last));
            lastday = new Date(lastday.setHours(0, 0, 0, 0));

            this.rangepicaker = [lastday, firstday];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            this.getSkuWiseListWithFilter();
          },
        },
        {
          text: "Last 30 Days",
          onClick: () => {
            let first = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
            first = new Date(first.setHours(0, 0, 0, 0));
            let last = new Date();
            last = new Date(last.setHours(0, 0, 0, 0));

            this.rangepicaker = [first, last];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            this.getSkuWiseListWithFilter();
          },
        },
        {
          text: "This Month",
          onClick: () => {
            let date = new Date();
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            // let lastDay = date;
            // lastDay = new Date(lastDay.setHours(0, 0, 0, 0));
            let lastDay = new Date();

            this.rangepicaker = [firstDay, lastDay];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            this.getSkuWiseListWithFilter();
          },
        },
        {
          text: "Last Month",
          onClick: () => {
            let now = new Date();
            let lastday = new Date(now.getFullYear(), now.getMonth(), 0);
            let firstday = new Date(
              lastday.getFullYear(),
              lastday.getMonth(),
              1
            );
            // this.rangepicaker = [firstday, lastday];
            this.rangepicaker = [firstday, lastday.setHours(23, 59, 59, 999)];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            this.getSkuWiseListWithFilter();
          },
        },
        {
          text: "This Quarter",
          onClick: () => {
            // let todays = new Date();
            // let quarters = todays.getMonth() / 3;

            // let startCurrentQuarter = new Date(
            //   todays.getFullYear(),
            //   quarters * 3,
            //   1
            // );
            // let endCurrentQuarter = new Date(
            //   startCurrentQuarter.getFullYear(),
            //   startCurrentQuarter.getMonth() + 3,
            //   0
            // );
            let todays = new Date();
            let thisMonth = todays.getMonth();
            let startMonth = thisMonth > 2 ? thisMonth > 5 ? thisMonth > 8 ? 9 : 6 : 3 : 0;
            let startCurrentQuarter = new Date(
              todays.getFullYear(),
              startMonth,
              1
            );
            let endCurrentQuarter = new Date(
              startCurrentQuarter.getFullYear(),
              startMonth+3,
              0
            );
            endCurrentQuarter.setHours(23, 59, 59, 999);

            this.rangepicaker = [startCurrentQuarter, endCurrentQuarter];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            this.getSkuWiseListWithFilter();
          },
        },
        {
          text: "Last Quarter",
          onClick: () => {
            // let todays = new Date();
            // let quarters = todays.getMonth() / 3;

            // let startLastQuarter = new Date(
            //   todays.getFullYear(),
            //   quarters * 3 - 3,
            //   1
            // );
            // let endLastQuarter = new Date(
            //   startLastQuarter.getFullYear(),
            //   startLastQuarter.getMonth() + 3,
            //   0
            // );
            let todays = new Date();
            let thisMonth = todays.getMonth()+1;
            let thisYear = todays.getFullYear();
            let startMonth = thisMonth > 3 ? thisMonth > 6 ? thisMonth > 9 ? 7 : 4 : 1 : 10;
            let startYear = thisMonth > 3 ? thisYear : thisYear-1;
            let startLastQuarter = new Date(
              startYear,
              startMonth-1,
              1
            );
            let endLastQuarter = new Date(
              startLastQuarter.getFullYear(),
              startLastQuarter.getMonth() + 3,
              0
            );
            endLastQuarter.setHours(23, 59, 59, 999);
            this.rangepicaker = [startLastQuarter, endLastQuarter];

            this.startDate = moment(this.rangepicaker[0]).valueOf();
            this.endDate = moment(this.rangepicaker[1]).valueOf();
            this.selectedDateType = "Custom";
            this.getSkuWiseListWithFilter();
          },
        },
      ],

      sortByListValues: "BestPerforming",
      sortByListOptions: [],

      brandsListValues: [],
      brandsListOptions: [],
      channelListValues: [],
      channelOrderName: "",
      tableProperties: {
        fields: [
          {
            key: "sku_title",
            label: "SKU/Title",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: false,
          },
          {
            key: "orders",
            label: "Orders",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: true,
          },
          {
            key: "averageOrderValue",
            label: "Average Order Value",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: true,
          },
          {
            key: "averageRemittedValue",
            label: "Average Remitted Value",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: true,
          },
          {
            key: "averageRemittedPerc",
            label: "Average Remitted %",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: true,
          },
          {
            key: "zeroOrNegavtiveRemittedOrder",
            label: "Zero/Negative Remitted Orders",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: true,
          },
          {
            key: "zeroOrNegavtiveRemittedOrderPerc",
            label: "Zero/Negative Remitted Orders %",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: true,
          },
          {
            key: "unRemittedOrder",
            label: "Unremitted Orders",
            thClass: "thClass",
            tdClass: "tdClass",
            sortable: true,
          },
        ],
        items: [],

        perPage: 50,
        currentPage: 1,
        totalRows: 0,
      },
    };
  },

  created() {
    // // default last 30 days
    // let first = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    // first = new Date(first.setHours(0, 0, 0, 0));
    // let last = new Date();
    // // last = new Date(last.setHours(0, 0, 0, 0));
    // this.rangepicaker = [first, last];

    // this.startDate = moment(this.rangepicaker[0]).valueOf();
    // this.endDate = moment(this.rangepicaker[1]).valueOf();
    // this.selectedDateType = "Custom";
    // //
    // this.getBrandsListing();
    // this.getPerformanceList();
    // this.getSkuWiseListWithFilter();
    if(this.isGetData){
      this.getData();
    }
  },

  methods: {
    getData(){
        // default last 30 days
      let first = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
      first = new Date(first.setHours(0, 0, 0, 0));
      let last = new Date();
      // last = new Date(last.setHours(0, 0, 0, 0));
      this.rangepicaker = [first, last];

      this.startDate = moment(this.rangepicaker[0]).valueOf();
      this.endDate = moment(this.rangepicaker[1]).valueOf();
      this.selectedDateType = "Custom";
      //
      this.getBrandsListing();
      this.getPerformanceList();
      this.getSkuWiseListWithFilter();
    },

    getBrandsListing() {
      getBrandsListing(this.companyID)
        .then((res) => {
          if (!res.errorCode) {
            let resp = res.response;
            resp.forEach((element) => {
              this.brandsListOptions.push({
                id: element,
                label: element,
              });
            });
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getPerformanceList() {
      this.sortByListOptions = [];
      var sortBy = [];
      this.sortByListOptions.push({
        value: null,
        text: "Best performing",
      });
      getPerformanceList()
        .then((res) => {
          if (!res.errorCode) {
            let sortBy = res.response;
            sortBy.forEach((element) => {
              this.sortByListOptions.push({
                value: element.performanceValue,
                text: element.text,
              });
            });
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // get detail of sku-wise table data

    getSkuWiseListWithFilter() {
      this.tableLoading = true;
      this.tableProperties.items = [];
      getSkuWiseListWithFilter(
        this.tableProperties.currentPage - 1,
        this.tableProperties.perPage,
        this.startDate,
        this.endDate,
        this.channelListValues,
        this.brandsListValues,
        this.sortByListValues,
        this.selectedDateType,
        this.companyID,
      )
        .then((res) => {
          this.tableLoading = false;
          if (!res.errorCode) {
            let resp = res.response;
            this.tableProperties.items = resp.content || [];
            this.tableProperties.totalRows = resp.totalElements;
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // download sku wise remittance

    downloadSkuWise() {
      this.loadingWidget = true;
      downloadSkuWiseRemittance(
        this.startDate,
        this.endDate,
        this.channelListValues,
        this.brandsListValues,
        this.sortByListValues,
        this.selectedDateType,
        this.companyID,
      )
        .then((res) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = "Downloaded Successfully";
          this.alertType = "Success";
          this.isConfirmAlert = false;
        })
        .catch((err) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getSelectedPage(currentPage) {
      this.tableProperties.currentPage = currentPage;
      this.getSkuWiseListWithFilter();
    },

    getSelectedPerPage(perPage) {
      this.tableProperties.perPage = perPage;
      this.tableProperties.currentPage = 1;
      this.getSkuWiseListWithFilter();
    },

    selectedSortByCategory(event) {
      this.sortByListValues = event;
      this.tableProperties.currentPage = 1;
      this.getSkuWiseListWithFilter();
    },

    selectedBrandsCategory(event) {
      this.brandsListValues = event;
      this.tableProperties.currentPage = 1;
      this.getSkuWiseListWithFilter();
    },

    selectedChannelsCategory(event) {
      this.channelListValues = event;
      this.tableProperties.currentPage = 1;
      this.getSkuWiseListWithFilter();
    },

    // clear date
    clearDateRange() {
      let first = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
      first = new Date(first.setHours(0, 0, 0, 0));
      let last = new Date();
      // last = new Date(last.setHours(0, 0, 0, 0));
      this.rangepicaker = [first, last];

      this.startDate = moment(this.rangepicaker[0]).valueOf();
      this.endDate = moment(this.rangepicaker[1]).valueOf();
      this.selectedDateType = "Custom";
      this.getSkuWiseListWithFilter();
    },
    // selected custom date range
    getSelectedDateRange() {
      this.startDate = moment(this.rangepicaker[0]).valueOf();
      this.endDate = moment(this.rangepicaker[1]).valueOf();
      this.selectedDateType = "Custom";
      this.getSkuWiseListWithFilter();
    },

    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    // for open a desktop filter
    openDesktop_filter(){
      this.isDesktopFilterOpen=!this.isDesktopFilterOpen;
    },
    closeDesktopFilter(){
      this.isDesktopFilterOpen=false;
    }
  },
};
</script>

<style scoped>
.filters ::v-deep .custom-select {
  height: 37px !important;
}
::v-deep .form-control {
  height: 33px !important;
}
.show-class {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  line-height: 2;
  text-align: left;
  color: #767676;
}
.performance ::v-deep .select_style .custom-select option:first-child {
  display: none;
}

.sku-remitance ::v-deep table thead tr th {
  text-align: center !important;
}
.sku-remitance ::v-deep table tbody tr td {
  text-align: center !important;
}
.sku-remitance ::v-deep table thead tr th:first-child {
  text-align: left !important;
  width: 25%;
}
.sku-remitance ::v-deep table tbody tr td:first-child {
  text-align: left !important;
}
.close-filter{
  position: absolute;
  top: 0px;
  right: 20px;
}

.sku-remitance ::v-deep .remitteance-pagination{
  margin-left: auto;
}

@media screen and (max-width:768px) {
.remittance-filter ::v-deep .mx-range-wrapper {
    width: 100%;
  }
.remittance-filter::v-deep .mx-calendar {
    width: 100%;
  }
.remittance-filter::v-deep .mx-calendar-content {
    width: 100%;
  }
.remittance-filter::v-deep .mx-datepicker-popup {
    left: 0 !important;
    top: 0 !important;
  }
}
@media screen and (max-width:800px) {
  .sku-remitance{
    margin-top: 2rem !important;
  }
}
@media screen and (max-width:340px) {
  .sku-remitance ::v-deep .remitteance-pagination{
    display: flex !important;
  }
  .sku-remitance ::v-deep .remitteance-pagination .page-link{
    min-width: 20px !important;
  }
  .sku-remitance ::v-deep .remitteance-pagination .show-class {
    margin-left: 7px;
  }
}
</style>
